import Container from '@mui/material/Container';

import CustomerPropertiesTable from './CustomerPropertiesTable';


const CustomerPropertiesPage = () => {
  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h2>Edit Customer Properties</h2>
      <CustomerPropertiesTable/>
    </Container>
  )
}


export default CustomerPropertiesPage;
