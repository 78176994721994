import { NavLink } from "react-router-dom";

import { useAppSelector } from 'modules/store';
import { getCurrentEnvironment } from 'modules/app/selectors';
import { getCurrentUser } from 'modules/user/selectors';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import SvgIcon from '@mui/material/SvgIcon';
import StorageIcon from '@mui/icons-material/Storage';
import WarningIcon from '@mui/icons-material/Warning';
import WorkIcon from '@mui/icons-material/Work';

import { Environment, UserRoles } from 'api/types';
import { AUTH_URL } from 'constants/resources';
import { EnvironmentPalette } from 'Theme';

import './style.css';
import { Insights } from "@mui/icons-material";


export const bootToLogout = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'carbon-dashboard',
  });

  window.location.href = `${AUTH_URL}/logout?${params}`;
}


const EnvironmentIndicator = () => {
  const environment = useAppSelector(getCurrentEnvironment);

  let env = '';

  switch (environment) {
    case Environment.Production:
      env = 'Prod';
      break;
    case Environment.Development:
      env = 'QA';
      break;
    default:
      env = 'Local';
  }

  return (
    <div className="environment-indicator--container">
      <div>Environment</div>
      <div className="environment-indicator--value">{env}</div>
    </div>
  );
}

const Sidebar = () => {
  const activeClassName = "sidebar-nav-link-selected";
  const inactiveClassName = "sidebar-nav-link";

  // Set the color theme to make it obvious which environment we're editing.
  const environment = useAppSelector(getCurrentEnvironment);

  const currentUserRole = useAppSelector(getCurrentUser)?.role;
  return (
    <nav className="sidebar-nav" style={{backgroundColor: EnvironmentPalette[environment]}}>
      <ul className="sidebar-ul">
        <li>
          <EnvironmentIndicator/>
        </li>
        <li>
          <NavLink
            to="/customers"
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <AdminPanelSettingsIcon fontSize='small'/>
              </SvgIcon>
              Customers
            </div>
          </NavLink>
        </li>
        {currentUserRole === UserRoles.UADMIN && <li>
          <NavLink
            to="/database"
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <StorageIcon fontSize='small'/>
              </SvgIcon>
              Database
            </div>
          </NavLink>
        </li>}
        <li>
          <NavLink
            to="/data-issues"
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <WarningIcon fontSize='small'/>
              </SvgIcon>
              Data Issues
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/worker-jobs"
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <WorkIcon fontSize='small'/>
              </SvgIcon>
              Worker Jobs
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/region-events"
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <Insights fontSize='small'/>
              </SvgIcon>
              Grid Data Viz
            </div>
          </NavLink>
        </li>

        <li>
          <div
            className={inactiveClassName}
            onClick={bootToLogout}
          >
            <div className="sidebar-tab">
              <SvgIcon sx={{mr: 2}}>
                <LogoutIcon fontSize='small'/>
              </SvgIcon>
              Logout
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}


export default Sidebar;
