import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICustomer } from "api/types";


interface ICustomerSlice {
  selectedCustomer: null | ICustomer
  templateCustomer: null | ICustomer
};


const initialState: ICustomerSlice = {
  selectedCustomer: null,
  templateCustomer: null,
};


const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setSelectedCustomer: (state, action: PayloadAction<{customer: ICustomer}>) => {
      state.selectedCustomer = action.payload.customer;
    },
    setTemplateCustomer: (state, action: PayloadAction<{customer: ICustomer}>) => {
      state.templateCustomer = action.payload.customer;
    },
  },
});


export default customerSlice.reducer;
export const { setSelectedCustomer, setTemplateCustomer } = customerSlice.actions;
