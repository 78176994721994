import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';

import { useGetAvailabilityForRegionQuery, useGetAvailableRegionsQuery, useSearchRegionEventsQuery } from "api/region_events";
import moment, { Moment } from "moment";
import { eventTypeToPath, makeChartOptions, makeSeriesFromSearchData } from "./utils";

import './style.css';


type MaybeDate = Moment | null;


const RegionEventsPage = () => {
  const allRegionsAvailableRes = useGetAvailableRegionsQuery(null);
  const [region, setRegion] = useState<string>('');
  const [eventType, setEventType] = useState<string>('');
  const [source, setSource] = useState<string>('');
  const [timeHorizon, setTimeHorizon] = useState<string>('');
  const [startDate, setStartDate] = useState<MaybeDate>(moment().startOf('day'));
  const [endDate, setEndDate] = useState<MaybeDate>(moment().endOf('day'));
  const [focusOn, setFocusOn] = useState<null | FocusedInputShape>(null);
  const regionEventRes = useGetAvailabilityForRegionQuery(region || '', {skip: !region});
  const searchResultsRes = useSearchRegionEventsQuery({
    region,
    source,
    start: startDate?.toISOString() || '',
    end: endDate?.toISOString() || '',
    path: eventTypeToPath[eventType],
    resolution: timeHorizon,
  }, {skip: !region || !source || !eventType || !timeHorizon || !startDate || !endDate})

  const regionEvent = useMemo(() => {
    return regionEventRes.data?.event_types.find(et => et.event_type === eventType)
  }, [regionEventRes, eventType]);

  const regionSource = useMemo(() => {
    return regionEvent?.sources.find(s => s.source === source)
  }, [source, regionEvent]);

  const loading = allRegionsAvailableRes.isLoading || allRegionsAvailableRes.isFetching;
  const dataLoading = searchResultsRes.isLoading || searchResultsRes.isFetching;

  useEffect(() => {
    if (region) {
      setEventType('');
      setSource('');
      setTimeHorizon('');
    }
  }, [region]);

  const onChangeDate = ({startDate: newStartDate, endDate: newEndDate}: {startDate: MaybeDate, endDate: MaybeDate}) => {
    const start = newStartDate || startDate || moment('2022-02-03');
    const end = newEndDate || endDate || moment('2022-02-07');

    setStartDate(start?.startOf('day') || null);
    setEndDate(end?.endOf('day') || null);
  };

  const series = useMemo(() => {
    if (searchResultsRes.data) {
      return makeSeriesFromSearchData(searchResultsRes.data, eventType);
    }
  }, [searchResultsRes, eventType]);

  return <div>
    <h1>Search region events</h1>
    <div className="region-events-search--datepicker">
      <DateRangePicker
        startDate={startDate}
        minimumNights={0}
        startDateId={startDate ? startDate.toISOString() : 'none'}
        endDate={endDate}
        endDateId={endDate ? endDate.toISOString() : 'none'}
        onDatesChange={onChangeDate}
        focusedInput={focusOn}
        onFocusChange={setFocusOn}
        small
        enableOutsideDays={false}
        isOutsideRange={(d) => regionSource ? d >= moment(regionSource.latest_start_date) || d < moment(regionSource.earliest_start_date) : false}
      />
    </div>
    {!loading && <FormControl>
        <InputLabel size="small" style={{fontSize: '12px'}} id="region-events-search-region--label">Region</InputLabel>
        <Select size="small" labelId="region-events-search-region--label" style={{minWidth: 160, fontSize: '12px', marginRight: '16px'}} value={region || ''} onChange={(e) => setRegion(e.target.value)} label="Region">
          {allRegionsAvailableRes.data?.regions.map(region => <MenuItem style={{fontSize: '12px'}} value={region.code} key={region.name}>{region.name} ({region.code})</MenuItem>)}
        </Select>
      </FormControl>
    }

    {region && <FormControl>
        <InputLabel size="small" style={{fontSize: '12px'}} id="region-events-search-event_type--label">Event</InputLabel>
        <Select size="small" labelId="region-events-search-event_type--label" style={{minWidth: 160, fontSize: '12px', marginRight: '16px'}} value={eventType || ''} onChange={(e) => setEventType(e.target.value)}>
          {regionEventRes.data?.event_types.map(eventType => <MenuItem style={{fontSize: '12px'}} value={eventType.event_type} key={eventType.name}>{eventType.name} ({eventType.event_type})</MenuItem>)}
        </Select>
      </FormControl>
    }

    {eventType &&
      <FormControl>
        <InputLabel size="small" style={{fontSize: '12px'}} id="region-events-search-source--label">Source</InputLabel>
        <Select size="small" labelId="region-events-search-source--label" style={{minWidth: 160, fontSize: '12px', marginRight: '16px'}} value={source || ''} onChange={(e) => setSource(e.target.value)}>
          {regionEvent?.sources.map(source => <MenuItem style={{fontSize: '12px'}} value={source.source} key={source.source}>{source.name} ({source.source})</MenuItem>)}
        </Select>
      </FormControl>
    }

    {source &&
      <FormControl>
        <InputLabel size="small" style={{fontSize: '12px'}} id="region-events-search-horizon--label">Resolution</InputLabel>
        <Select size="small" labelId="region-events-search-horizon--label" style={{minWidth: 160, fontSize: '12px'}} value={timeHorizon || ''} onChange={(e) => setTimeHorizon(e.target.value)}>
          {regionEvent?.sources.find(s => s.source === source)?.resolutions.map(res => <MenuItem style={{fontSize: '12px'}} key={res} value={res}>{res}</MenuItem>)}
        </Select>
      </FormControl>
    }
    {dataLoading && <LinearProgress />}
    {searchResultsRes.data &&
      <HighchartsReact
        highcharts={Highcharts}
        options={makeChartOptions({
          animated: true,
          dateResolution: timeHorizon,
          chartAxisLabelY1: 'MW or lbs/MWh',
          chartData: series || [],
          chartType: 'spline',
        })}
      />
    }

  </div>
};

export default RegionEventsPage;