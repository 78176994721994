import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';

import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import userReducer from 'modules/user/slice';
import customerReducer from 'modules/customer/slice';
import appReducer from 'modules/app/slice';

import { api } from 'api/base';


const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  user: userReducer,
  customer: customerReducer,
  app: appReducer,
});


const rootReducer = (state: any, action: Action) => {
  return reducers(state, action);
};


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
