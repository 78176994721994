import { Outlet } from "react-router-dom";


const CustomersLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default CustomersLayout;