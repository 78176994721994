import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'modules/store';
import { receiveUser } from 'modules/user/slice';
import { getCurrentEnvironment } from 'modules/app/selectors';
import { setSelectedCustomer } from 'modules/customer/slice';
import { API_URL } from 'constants/resources';

import { IUser } from 'api/types';

import CustomersPage from 'components/Customers';
import UsersPage from 'components/Users';
import Sidebar from 'components/Sidebar';
import CustomerPropertiesPage from 'components/CustomerProperties';
import CustomerAssetsPage from 'components/CustomerAssets';
import ConnectorsPage from 'components/Connectors';
import DatabasePage from 'components/DatabasePage';
import DashboardConfigPage from 'components/DashboardConfig';

import { getToken } from 'utils/auth';

import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material';

import Theme, { EnvironmentPalette } from './Theme';

import './App.css';
import { bootToLogin } from 'api/base';
import DataIssuesPage from 'components/DataIssuesPage';
import CustomersLayout from 'layouts/Customers';
import CustomerPage from 'components/Customer';
import CustomerLayout from 'layouts/Customer';
import RegionEventsPage from 'components/RegionEventsPage';
import WorkerJobsPage from 'components/WorkerJobsPage';



// Compose a component with the navigation sidebar.
const SidebarComponent = (props: { children: any }) => {
  return (
    <React.Fragment>
      <Sidebar/>
      <Container maxWidth={false} className="se--view-container">
        {props.children}
      </Container>
    </React.Fragment>
  );
}


function App() {
  const [bootstrapStatus, setBootstrapStatus] = useState<'not_started' | 'in_progress' | 'done'>('not_started');

  const dispatch = useAppDispatch();

  // Set the color theme to make it obvious which environment we're editing.
  const environment = useAppSelector(getCurrentEnvironment);
  Theme.palette.primary.main = EnvironmentPalette[environment];

  const bootstrap = () => {
    setBootstrapStatus('in_progress');

    const userToken = getToken();
    axios.get<{data: {user: IUser}}>(`${API_URL}/api/v1/users/me`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
        withCredentials: true,
      })
      .then(({data}) => {
        dispatch(receiveUser(data.data));
        dispatch(setSelectedCustomer({
          customer: {
            id: data.data.user.customer_id,
            parent_id: 'unknown',
            name: 'unknown',
            status: 'active',
            created_at: 'unknown',
            api_key_id: null,
            api_key_value: null,
            usage_plan_id: null
          }
        }));
        setBootstrapStatus('done');
      })
      .catch((error) => {
        bootToLogin();
      });
  }

  useEffect(() => {
    if (bootstrapStatus === 'not_started') {
      bootstrap();
    }
  });

  if (bootstrapStatus !== 'done') return <div>Loading...</div>

  return (
    <ThemeProvider theme={createTheme(Theme)}>
      <BrowserRouter>
        <div className="App se--app-container">
          <Routes>
            <Route path="/customers" element={
              <SidebarComponent>
                <CustomersLayout />
              </SidebarComponent>
            }>
              <Route index element={<CustomersPage />}/>
              <Route path=":customerId" element={<CustomerLayout />}>
                <Route index element={<CustomerPage />}/>
                <Route path="dashboard_config" element={<DashboardConfigPage />} />
                <Route path="customer_properties" element={<CustomerPropertiesPage />} />
                <Route path="customer_assets" element={<CustomerAssetsPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="connectors" element={<ConnectorsPage />} />
              </Route>
            </Route>
            <Route path="/database" element={
              <SidebarComponent>
                <DatabasePage/>
              </SidebarComponent>
            }/>
            <Route path="/settings" element={
              <SidebarComponent>
                <p>Not implemented yet.</p>
              </SidebarComponent>
            } />
            <Route path="/data-issues" element={
              <SidebarComponent>
                <DataIssuesPage />
              </SidebarComponent>
            } />
            <Route path="/worker-jobs" element={
              <SidebarComponent>
                <WorkerJobsPage />
              </SidebarComponent>
            } />
            <Route path="/region-events" element={
              <SidebarComponent>
                <RegionEventsPage />
              </SidebarComponent>
            } />
            <Route path="/*" element={<Navigate to="/customers" replace />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}


export default App;
