import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { Breadcrumbs, LinearProgress } from "@mui/material";

import { useGetCustomerQuery } from "api/customers";
import { useAppSelector } from "modules/store";
import { getCurrentUser } from "modules/user/selectors";
import { UserRoles } from "api/types";

import './style.css';


const CustomerLayout = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const currentUserRole = useAppSelector(getCurrentUser)?.role;

  const {
    data,
    isFetching,
    isLoading,
  } = useGetCustomerQuery(customerId)

  const loading = isLoading || isFetching;

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/customers">
          Customers
        </Link>
        <Link color="inherit" to={`/customers/${customerId}`}>
          {data?.data.name || 'Customer'}
        </Link>
      </Breadcrumbs>
      <h1>{loading ? <LinearProgress /> : <div>{data?.data.name}</div>}</h1>
      <div className="customer-layout-links--container">
        <NavLink to={`/customers/${customerId}`}>Summary</NavLink>
        <NavLink to={`/customers/${customerId}/dashboard_config`}>Dashboard config</NavLink>
        {currentUserRole === UserRoles.UADMIN && <NavLink to={`/customers/${customerId}/customer_properties`}>Properties</NavLink>}
        <NavLink to={`/customers/${customerId}/customer_assets`}>Assets</NavLink>
        <NavLink to={`/customers/${customerId}/users`}>Users</NavLink>
        <NavLink to={`/customers/${customerId}/connectors`}>Connectors</NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export default CustomerLayout;