interface IRegion {
  name: string;
  code: string;
}

export const euRegions: IRegion[] = [
  {name: 'Albania',
  code: "AL"},
  {name: 'Armenia',
  code: "AM"},
  {name: 'Austria',
  code: "AT"},
  {name: 'Azerbaijan',
  code: "AZ"},
  {name: 'Belarus',
  code: "BY"},
  {name: 'Bosnia and Herzegovina',
  code: "BA"},
  {name: 'Belgium',
  code: "BE"},
  {name: 'Bulgaria',
  code: "BG"},
  {name: 'Switzerland',
  code: "CH"},
  {name: 'Czech Republic',
  code: "CZ"},
  {name: '50Hertz Transmission, Germany',
  code: "DE.50HT"},
  {name: 'Amprion, Germany',
  code: "DE.AMP"},
  {name: 'TransnetBW GmbH, Germany',
  code: "DE.TBW"},
  {name: 'TenneT TSO, Germany',
  code: "DE.TT"},
  {name: 'Germany',
  code: "DE"},
  {name: 'Denmark',
  code: "DK"},
  {name: 'Estonia',
  code: "EE"},
  {name: 'Spain',
  code: "ES"},
  {name: 'Finland',
  code: "FI"},
  {name: 'France',
  code: "FR"},
  {name: 'Georgia',
  code: "GE"},
  {name: 'Greece',
  code: "GR"},
  {name: 'Croatia',
  code: "HR"},
  {name: 'Hungary',
  code: "HU"},
  {name: 'Ireland',
  code: "IE"},
  {name: 'Italy',
  code: "IT"},
  {name: 'Lithuania',
  code: "LT"},
  {name: 'Luxembourg',
  code: "LU"},
  {name: 'Latvia',
  code: "LV"},
  {name: 'Moldova',
  code: "MD"},
  {name: 'Montenegro',
  code: "ME"},
  {name: 'North Macedonia',
  code: "MK"},
  {name: 'Malta',
  code: "MT"},
  {name: 'Netherlands',
  code: "NL"},
  {name: 'Norway',
  code: "NO"},
  {name: 'Poland',
  code: "PL"},
  {name: 'Portugal',
  code: "PT"},
  {name: 'Romania',
  code: "RO"},
  {name: 'Serbia',
  code: "RS"},
  {name: 'Russia',
  code: "RU"},
  {name: 'Kaliningradskaja oblast, Russia',
  code: "RU.KGD"},
  {name: 'Sweden',
  code: "SE"},
  {name: 'Slovenia',
  code: "SI"},
  {name: 'Slovakia',
  code: "SK"},
  {name: 'Turkey',
  code: "TR"},
  {name: 'Ukraine',
  code: "UA"},
  {name: 'Dobrotvira TPP, Ukraine',
  code: "UA.DTPP"},
  {name: 'United Kingdom',
  code: "UK"},
  {name: 'Kosovo',
  code: "XK"},
]


export const eiaRegions: IRegion[] = [
  {code:"AEC", name:"PowerSouth Energy Cooperative"},
  {code:"AECI", name:"Associated Electric Cooperative"},
  {code:"AMPL", name:"AMPL"},
  {code:"AVA", name:"Avista Corporation"},
  {code:"AVRN", name:"Avangrid Renewables"},
  {code:"AZPS", name:"Arizona Public Service Company"},
  {code:"BANC", name:"Balancing Authority of Northern California"},
  {code:"CHPD", name:"Public Utility District No. 1 of Chelan County"},
  {code:"CPLE", name:"Duke Energy Progress East"},
  {code:"CPLW", name:"Duke Energy Progress West"},
  {code:"DEAA", name:"Arlington Valley"},
  {code:"DOPD", name:"PUD No. 1 of Douglas County"},
  {code:"DUK", name:"Duke Energy Carolinas"},
  {code:"EEI", name:"Electric Energy"},
  {code:"EPE", name:"El Paso Electric Company"},
  {code:"ERCO", name:"Electric Reliability Council of Texas"},
  {code:"FMPP", name:"Florida Municipal Power Pool"},
  {code:"FPC", name:"Duke Energy Florida"},
  {code:"FPL", name:"Florida Power & Light Co."},
  {code:"GCPD", name:"Public Utility District No. 2 of Grant County"},
  {code:"GLHB", name:"GridLiance"},
  {code:"GRID", name:"Gridforce Energy Management"},
  {code:"GRIF", name:"Griffith Energy"},
  {code:"GRIS", name:"GRIS"},
  {code:"GRMA", name:"GRMA"},
  {code:"GVL", name:"Gainesville Regional Utilities"},
  {code:"GWA", name:"NaturEner Power Watch"},
  {code:"HGMA", name:"New Harquahala Generating Company"},
  {code:"HST", name:"City of Homestead"},
  {code:"IID", name:"Imperial Irrigation District"},
  {code:"IPCO", name:"Idaho Power Company"},
  {code:"JEA", name:"Jacksonville Electric Authority"},
  {code:"LDWP", name:"Los Angeles Department of Water and Power"},
  {code:"LGEE", name:"Louisville Gas and Electric Company and Kentucky Utilities Company"},
  {code:"NEVP", name:"Nevada Power Company"},
  {code:"NSB", name:"Utilities Commission of New Smyrna Beach"},
  {code:"NWMT", name:"NorthWestern Corporation"},
  {code:"OVEC", name:"Ohio Valley Electric Corporation"},
  {code:"PACE", name:"PacifiCorp East"},
  {code:"PACW", name:"PacifiCorp West"},
  {code:"PGE", name:"Portland General Electric Company"},
  {code:"PNM", name:"Public Service Company of New Mexico"},
  {code:"PSCO", name:"Public Service Company of Colorado"},
  {code:"PSEI", name:"Puget Sound Energy"},
  {code:"SC", name:"South Carolina Public Service Authority"},
  {code:"SCEG", name:"Dominion Energy South Carolina"},
  {code:"SCL", name:"Seattle City Light"},
  {code:"SEC", name:"Seminole Electric Cooperative"},
  {code:"SEPA", name:"Southeastern Power Administration"},
  {code:"SOCO", name:"Southern Company Services"},
  {code:"SPA", name:"Southwestern Power Administration"},
  {code:"SRP", name:"SRP"},
  {code:"TAL", name:"City of Tallahassee"},
  {code:"TEC", name:"Tampa Electric Company"},
  {code:"TEPC", name:"Tucson Electric Power"},
  {code:"TIDC", name:"Turlock Irrigation District"},
  {code:"TPWR", name:"City of Tacoma"},
  {code:"TVA", name:"Tennessee Valley Authority"},
  {code:"WACM", name:"Western Area Power Administration - Rocky Mountain Region"},
  {code:"WALC", name:"Western Area Power Administration - Desert Southwest Region"},
  {code:"WAUW", name:"Western Area Power Administration - Upper Great Plains West"},
  {code:"WWA", name:"NaturEner Wind Watch"},
  {code:"YAD", name:"Alcoa Power Generating"},
];

export const isoRegions: IRegion[] = [
  {
    code:'AESO',
    name:'Alberta Electric System Operator',
  },
  {
    code:'BPA',
    name:'Bonneville Power Administration',
  },
  {
    code:'CAISO',
    name:'California ISO',
  },
  {
    code:'ERCOT',
    name:'Electric Reliability Council of Texas',
  },
  {
    code:'IESO',
    name:'Independent Electricity System Operator',
  },
  {
    code:'ISONE',
    name:'ISO New England',
  },
  {
    code:'MISO',
    name:'Midcontinent Independent System Operator',
  },
  {
    code:'MISO.NORTH',
    name:'MISO North Region',
  },
  {
    code:'MISO.CENTRAL',
    name:'MISO Central Region',
  },
  {
      code:'MISO.SOUTH',
      name:'MISO South Region',
  },
  {
      code:'NYISO',
      name:'New York ISO',
  },
  {
      code:'NYISO.NYLI',
      name:'New York - Long Island',
  },
  {
      code:'NYISO.NYCW',
      name:'New York - NYC / Westchester',
  },
  {
      code:'NYISO.NYUP',
      name:'New York - Upstate',
  },
  {
      code:'PJM',
      name:'PJM Interconnection',
  },
  {
      code:'SPP',
      name:'Southwest Power Pool',
  },
];

export const allRegions: IRegion[] = isoRegions.concat(eiaRegions).concat(euRegions);