import React, { useState, useEffect } from 'react';

import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridRowId,
  GridToolbar,
  GridSlotsComponent,
} from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';
import AddIcon from '@mui/icons-material/Add';


interface GenericUseMutationResult {
  isError: boolean
  isLoading: boolean
}


interface IEditableTableProps<RowDataType> {
  editable: boolean
  rows: RowDataType[]
  columnSchema: GridColDef[]
  pageSize: number
  showToolbar: boolean
  loading?: boolean

  // Save changes that have been made in the table. Disabled if undefined.
  saveCallback?: (updatedRows: RowDataType[]) => void
  saveState?: GenericUseMutationResult

  // Delete selected rows in the table. Disabled if undefined.
  deleteCallback?: (selectedRowIds: GridRowId[]) => void
  deleteState?: GenericUseMutationResult

  // An optional third action that can be performed on the table.
  customCallback?: (selectedRows: RowDataType[]) => void
  customState?: GenericUseMutationResult
  customButtonIcon?: React.ReactElement
  customButtonText?: string

  // An optional third action that can be performed on the table.
  secondaryCustomCallback?: (selectedRows: RowDataType[]) => void
  secondaryCustomState?: GenericUseMutationResult
  secondaryCustomButtonIcon?: React.ReactElement
  secondaryCustomButtonText?: string


  enableAddButton?: boolean
  // Function that returns the new, empty row data.
  emptyRowFactory?: () => RowDataType

  // The table will call this when the user clicks to a different page.
  onPageChange?: (newPage: number) => void
  onPageSizeChange?: (newSize: number) => void

  // Used by the table to figure out how many pages there are.
  rowCount?: number
}


// Require that the type parameter below has at least an 'id' property.
interface GenericRowDataType {
  id: GridRowId
}


export default function EditableTable<RowDataType extends GenericRowDataType>(props: IEditableTableProps<RowDataType>) {
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [modifiedRows, setModifiedRows] = useState(structuredClone(props.rows));

  // Update the local copy of the rows if the parent's rows change.
  // NOTE(milo): We need to apply this effect when the 0th row's id changes as
  // a way to detect when we've moved to a new page. Another option would be to
  // pass in the current page number as a prop.
  const firstId = props.rows[0]?.id
  useEffect(() => {
    setModifiedRows(structuredClone(props.rows));
  }, [props.rows.length, firstId, props.rows]);

  // Reset to the original project state that was passed into the table.
  const revertChanges = () => {
    setModifiedRows(structuredClone(props.rows));
  }

  const getSelectedRows = (rowIds: GridRowId[]): RowDataType[] => {
    return modifiedRows.filter((row: { id: GridRowId }) => rowIds.includes(row.id));
  }

  // Rows that the user has modified compared to the origin version.
  const dirtyRows = modifiedRows.filter((modifiedRow: RowDataType) => {
    const originalRow = props.rows.find((row) => row.id === modifiedRow.id);
    if (!originalRow) {
      return true;
    }
    return JSON.stringify(modifiedRow) !== JSON.stringify(originalRow);
  });

  let components: Partial<GridSlotsComponent> = {};
  if (props.showToolbar) {
    components['Toolbar'] = GridToolbar;
  }

  return (
    <div style={{ width: '100%' }}>
      <Stack direction="row-reverse" spacing={1} sx={{mb: 2}} alignItems="center">
        {
          (props.saveState && props.saveCallback) ?
            <LoadingButton
              color={props.saveState?.isError ? 'error' : 'primary'}
              loading={props.saveState?.isLoading}
              startIcon={<SaveIcon/>}
              variant="contained"
              size="small"
              disabled={dirtyRows.length === 0}
              onClick={async () => props.saveCallback && props.saveCallback(dirtyRows) }
            >
              Save
            </LoadingButton>
          : ''
        }
        <Button
          startIcon={<RestoreIcon/>}
          variant='outlined'
          onClick={revertChanges}
          disabled={dirtyRows.length === 0}
        >
          Revert
        </Button>
        {
          (props.deleteState && props.deleteCallback) ?
            <LoadingButton
              color={props.deleteState?.isError ? 'error' : 'primary'}
              loading={props.deleteState?.isLoading}
              startIcon={<DeleteIcon/>}
              variant="contained"
              size="small"
              disabled={selectedRowIds.length === 0}
              onClick={async () => props.deleteCallback && props.deleteCallback(selectedRowIds as string[])}
            >
              Delete
            </LoadingButton>
          : ''
        }
        {
          (props.customState && props.customCallback) ?
            <LoadingButton
              color={props.customState?.isError ? 'error' : 'primary'}
              loading={props.customState?.isLoading}
              startIcon={props.customButtonIcon}
              variant="contained"
              size="small"
              disabled={selectedRowIds.length === 0}
              onClick={async () => props.customCallback && props.customCallback(getSelectedRows(selectedRowIds))}
            >
              {props.customButtonText}
            </LoadingButton>
          : ''
        }
        {
          (props.secondaryCustomState && props.secondaryCustomCallback) ?
            <LoadingButton
              color={props.secondaryCustomState?.isError ? 'error' : 'primary'}
              loading={props.secondaryCustomState?.isLoading}
              startIcon={props.secondaryCustomButtonIcon}
              variant="contained"
              size="small"
              disabled={selectedRowIds.length === 0}
              onClick={async () => props.secondaryCustomCallback && props.secondaryCustomCallback(getSelectedRows(selectedRowIds))}
            >
              {props.secondaryCustomButtonText}
            </LoadingButton>
          : ''
        }
        {
          (props.enableAddButton && props.emptyRowFactory) ?
          <Button
            startIcon={<AddIcon/>}
            variant="contained"
            onClick={() => {
              if (!props.emptyRowFactory) {
                return;
              }
              setModifiedRows([props.emptyRowFactory(), ...modifiedRows]);
            }}
          >
            Add
          </Button>
          : ''
        }
      </Stack>
      <DataGrid
        loading={props.loading}
        rowCount={props.rowCount || props.rows.length}
        pagination={true}
        paginationMode={props.onPageChange ? "server" : "client"}
        onPageChange={props.onPageChange}
        editMode='cell'
        experimentalFeatures={{ newEditingApi: true }}
        rows={modifiedRows}
        columns={props.columnSchema}
        // The column buffer determines how many invisible columns on either side
        // of the view are rendered. To avoid render hooks being called conditionally
        // (and throwing an exception), we need to render all columns all the time.
        columnBuffer={10}
        pageSize={props.pageSize || 10}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={props.onPageSizeChange}
        autoHeight={true}
        components={components}
        checkboxSelection
        // https://github.com/mui/mui-x/issues/3654
        // NOTE(milo): Documentation isn't great because this is experimental.
        // Only processRowUpdate reflects the changes to the table correctly.
        processRowUpdate={(newRow: any) => {
          if (!props.editable) {
            return;
          }
          const rowIdx = modifiedRows.findIndex((value: RowDataType) => value.id === newRow.id);
          let rowsWithEditsApplied = structuredClone(modifiedRows);
          rowsWithEditsApplied[rowIdx] = newRow;
          setModifiedRows(rowsWithEditsApplied);
          return newRow;
        }}
        onProcessRowUpdateError={(error: any) => {
          console.error(error);
        }}
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          setSelectedRowIds(selectionModel);
        }}
      />
    </div>
  );
}
