import Container from '@mui/material/Container';
import { DataGrid, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { Select, MenuItem, Drawer, InputLabel, FormControl } from '@mui/material';
import { useState } from 'react';

import { useListWorkerJobsQuery, usePauseWorkerJobMutation, useRestartWorkerJobMutation } from 'api/worker_jobs';
import './style.css';
import { IWorkerJob } from 'api/types';
import { LoadingButton } from '@mui/lab';


const TableSizeSchema = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
  },
  {
    field: 'created_at',
    headerName: 'Started',
    type: 'string',
    width: 220,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      if (params.value == null) {
        return '';
      }
      return new Date(params.value).toLocaleString('default', dateFormat);
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last updated',
    type: 'string',
    width: 220,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      if (params.value == null) {
        return '';
      }
      return new Date(params.value).toLocaleString('default', dateFormat);
    },
  },
  {
    field: 'meta.progress_percent',
    headerName: 'Percent completion',
    type: 'float',
    valueGetter: (params: {row: IWorkerJob}) => {
      const progress: number = (params.row.meta.progress_percent as number) || 0.0;
      return progress;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toPrecision(3)}%`
    },
  },
];


const dateFormat: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};

const statusOptions = [
  {value: 'in_progress', label: 'In Progress'},
  {value: 'done', label: 'Done'},
  {value: 'paused', label: 'Paused'},
]

const WorkerJobsPage = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [viewingJob, setViewingJob] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>('in_progress');
  const [pause, pauseResult] = usePauseWorkerJobMutation();
  const [restart, restartResult] = useRestartWorkerJobMutation();

  const { data, isLoading, isFetching, isError } = useListWorkerJobsQuery(
    {
      page: page + 1,
      perPage: pageSize,
      status: selectedStatus,
    }
  );

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];

  const setViewingRow = (row: GridRowParams) => {
    setViewingJob(row.id as string);
  }

  const clearDrawer = () => {
    setViewingJob(null);
  }

  const selectedJob = rows.find(d => d.id === viewingJob);

  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h1>Worker Jobs</h1>
      <div>
        <FormControl size="small" sx={{mb: 2, minWidth: 160, maxWidth: 160}}>
          <InputLabel id="worker-jobs-status-label">Status</InputLabel>
          <Select
            labelId="worker-jobs-status-label"
            value={selectedStatus}
            label="Status"
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            {statusOptions.map((status) => (<MenuItem key={status.value} value={status.value} selected={status.value === selectedStatus}>{status.label}</MenuItem>))}
          </Select>
        </FormControl>
      </div>
      <DataGrid
        loading={isLoading}
        editMode='cell'
        rows={rows}
        columns={TableSizeSchema}
        // The column buffer determines how many invisible columns on either side
        // of the view are rendered. To avoid render hooks being called conditionally
        // (and throwing an exception), we need to render all columns all the time.
        columnBuffer={10}
        onRowClick={setViewingRow}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight={true}
        density={"compact"}
        rowCount={data ? (data.meta.pagination.last * pageSize) : pageSize}
      />
      <Drawer
        anchor="right"
        open={!!viewingJob}
        onClose={clearDrawer}
      >
        <div className="data-issues-drawer--content">
          <h4>Worker Job:</h4>
          <div>
            {['in_progress'].includes(selectedJob?.status || '') && <LoadingButton onClick={() => pause(selectedJob?.id || '')} loading={pauseResult.isLoading}>Pause job</LoadingButton>}
            {['paused', 'rejected'].includes(selectedJob?.status || '') && <LoadingButton onClick={() => restart(selectedJob?.id || '')} loading={restartResult.isLoading}>Restart job</LoadingButton>}
          </div>
          <div className="data-issues-drawer--data">
            <pre>{JSON.stringify(rows.find(d => d.id === viewingJob), null, 2)}</pre>
          </div>
        </div>
      </Drawer>
    </Container>
  )
}


export default WorkerJobsPage;
