import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch } from '@mui/material';
import cx from 'classnames';

import { Pages, PagesSections } from './constants';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

const PageToggle = ({
  page,
  onToggle,
  label,
  helpText,
  enabled,
}: {
  page: Pages,
  onToggle: () => void,
  label: string,
  helpText: string,
  enabled: boolean,
}) => {
  return (
    <div className={cx('dashboard-config-page-option--container', {enabled})}>
      <FormControlLabel
        control={
          <Switch checked={enabled} name={page} onChange={onToggle}/>
        }
        label={label}
      />
      <div className="dashboard-config-page-option--help">{helpText}</div>
    </div>
  );
};

const CustomerPagesSection = ({
  name,
  description,
  pages,
  onTogglePage,
  enabledPages,
}: {name: string, description: string, onTogglePage: (page: Pages) => void, pages: {page: Pages, label: string, description: string}[], enabledPages: Pages[]}) => {
  return <Accordion>
    <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
      <div>
        <p>{name}</p>
        <small>{description}</small>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      {pages.map(page => <PageToggle page={page.page} enabled={enabledPages.includes(page.page)} onToggle={() => onTogglePage(page.page)} label={page.label} helpText={page.description} />)}
    </AccordionDetails>
  </Accordion>
}

const CustomerPagesConfig = ({
  availablePages,
  onChange,
}: {availablePages: Pages[], onChange: (newPages: Pages[]) => void}) => {

  const togglePage = (page: Pages) => {
    if (availablePages.includes(page)) {
      onChange(availablePages.filter(p => p !== page));
    } else {
      onChange(availablePages.concat([page]));
    }
  };

  return (
    <Box
      component="div"
      sx={{ mt: 4 }}
    >
      <h3>
        Dashboard Pages
      </h3>
      <p>Select which pages a customer can view.</p>
      <div>
        {PagesSections.map(section => (
          <CustomerPagesSection
            key={section.name}
            enabledPages={availablePages}
            onTogglePage={togglePage}
            name={section.name}
            description={section.description}
            pages={section.pages}
          />
        ))}
      </div>
    </Box>
  );
}


export default CustomerPagesConfig;