
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { Alert, Autocomplete, AutocompleteChangeReason, Snackbar, TextField } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

import { getSelectedCustomer, getTemplateCustomer } from 'modules/customer/selectors';
import { getCurrentUser } from 'modules/user/selectors';
import { getCurrentEnvironment } from 'modules/app/selectors';
import { useGetCustomersQuery } from 'api/customers';

import './style.css';


const CustomerDropdown = (props: { forTemplateCustomer?: boolean }) => {
  const nav = useNavigate();

  const selectedCustomer = useSelector(props.forTemplateCustomer ? getTemplateCustomer : getSelectedCustomer);
  const currentUser = useSelector(getCurrentUser);
  const environment = useSelector(getCurrentEnvironment);

  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const { data = { data: [] } } = useGetCustomersQuery({
    // For now, don't set the customerId, giving us all customers since we're a uadmin.
    page: 1,
    perPage: 20,
    environment
  });

  const onChange = (_: SyntheticEvent<Element, Event>, cust: {id: string, label: string} | string, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') return;

    const custId = cust instanceof Object ? cust.id : cust;
    nav(`/customers/${custId}`)
  }

  return (
    <FormControl sx={props.forTemplateCustomer ? {} : {m: 2, mt: 4}} style={{display: 'block', maxWidth: 400}}>
      <Snackbar open={errorAlertOpen} autoHideDuration={2000} onClose={() => setErrorAlertOpen(false)} anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
        <Alert severity='error'>Customer not found</Alert>
      </Snackbar>
      <Autocomplete
        options={data.data.map(cust => ({ id: cust.id, label: `${cust.id} (${cust.name})`}))}
        disableClearable
        freeSolo
        value={{
          label: selectedCustomer?.id || currentUser?.customer_id || '',
          id: selectedCustomer?.id || currentUser?.customer_id || '',
        }}
        renderInput={params => <TextField {...params} label="Customer"/>}
        onChange={onChange}
      />
    </FormControl>
  );
}


export default CustomerDropdown;
