/**
 * This module defines the global style for Material UI components.
 * https://mui.com/material-ui/customization/how-to-customize/
 **/
import { Environment } from 'api/types';

import './index.css';


// Helper function for retrieving a CSS variable as a string.
const getCssVariable = (varname: string) => {
  const bodyStyles = window.getComputedStyle(document.body);
  return bodyStyles.getPropertyValue(varname).replace(' ', '');
}


export const GlobalPalette = {
  White: '#FFFFFF',
  Black: '#000000',
  Teal: getCssVariable('--se-color-teal'),
  Navy: getCssVariable('--se-color-navy'),
}


export const EnvironmentPalette = {
  [Environment.Localhost]: getCssVariable('--bs-teal'),
  [Environment.Development]: getCssVariable('--bs-orange'),
  [Environment.Production]: getCssVariable('--bs-red'),
}


const theme = {
  /*
  * GLOBAL THEME CONFIGURATION
  */
  palette: {
    primary: {
      main: GlobalPalette.Navy
    },
    secondary: {
      main: GlobalPalette.White,
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      '"Helvetica Neue"',
      '"Segoe UI"',
      'Roboto',
      '-apple-system',
      'sans-serif',
    ].join(',')
  },

  /*
  * COMPONENT THEME CONFIGURATION
  */
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: '6px',
          paddingBottom: '6px'
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: GlobalPalette.Navy,
        },
        indicator: {
          width: '5px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: GlobalPalette.White,
          fontWeight: 'normal',
          justifyContent: 'start',
          "&.Mui-selected": {
            background: 'rgba(255, 255, 255, 0.2)',
            color: GlobalPalette.White,
            fontWeight: 'bold',
          },
        },
      },
    },
  },
};


export default theme;
