import { api } from './base'


export interface IAllRegionsAvailability {
  regions: {
    code: string,
    description: string,
    name: string,
  }[]
}

export interface IRegionDataAvailability {
  event_types: {
    description: string,
    event_type: string,
    name: string,
    sources: {
      description: string,
      earliest_start_date: string,
      latest_start_date: string,
      name: string,
      resolutions: string[],
      source: string,
      latency_seconds?: {
        max: number,
        mean: number,
        median: number,
        min: number,
        stddev: number,
      }
      update_frequency?: {
        expected: string
      }
    }[],
  }[],
}

interface ISearchRegionEventsQuery {
  region: string,
  source: string,
  path: string,
  start: string,
  end: string,
  resolution: string,
  emission_factor_source?: string,
  pollutant?: string
}

export interface ICarbonIntensityData {
  consumed_rate_kg_per_mwh?: number
  consumed_rate_lb_per_mwh?: number
  generated_rate_kg_per_mwh?: number
  generated_rate_lb_per_mwh?: number
  marginal_rate_kg_per_mwh?: number
  marginal_rate_lb_per_mwh?: number
}

export interface IForecastedCarbonIntensityData extends ICarbonIntensityData {
  forecast_horizon_in_hours?: number
  forecast_horizon_in_minutes?: number
}


export interface ISearchRegionEventsResponse {
  data: {
    event_type: string,
    start_date: string,
    region: string,
    meta: Record<string, unknown>,
    data: ICarbonIntensityData | IForecastedCarbonIntensityData | Record<string, number>
  }[],
}


export const assetEventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAvailableRegions: build.query<IAllRegionsAvailability, null>({
      query: () => {
        return {
          url: 'region_events/list-available-regions',
          method: 'GET',
        }
      },
    }),

    getAvailabilityForRegion: build.query<IRegionDataAvailability, string>({
      query: (region: string) => {
        return {
          url: `region_events/list-data-for-region?region=${region}`,
          method: 'GET',
        }
      }
    }),

    searchRegionEvents: build.query<ISearchRegionEventsResponse, ISearchRegionEventsQuery>({
      query: (params: unknown) => {
        return {
          url: `region_events/search?${new URLSearchParams(params as Record<string, string>)}`,
          method: 'GET',
        }
      }
    })
  })
});


export const {
  useGetAvailableRegionsQuery,
  useGetAvailabilityForRegionQuery,
  useSearchRegionEventsQuery,
} = assetEventsApi;
