
import { ICustomerAsset, AssetType } from 'api/types';
import { useCreateCustomerAssetsMutation, useDeleteCustomerAssetsMutation, useListCustomerAssetsQuery, useUpdateCustomerAssetsMutation } from 'api/assets';

import EditableTable from 'components/EditableTable';

import { useState } from 'react';
import { useGetCustomerQuery } from 'api/customers';
import { useParams } from 'react-router-dom';

const timeResolutionToName = {
  '5m': '5 minutes',
  '15m': '15 minutes',
  '1h': '1 hour',
  '1d': '1 day',
  '1M': '1 month',
  '1Y': '1 year',
}

const ColumnSchema = [
  {
    field: 'id',
    headerName: 'Asset ID',
    width: 200,
    type: 'string',
    sortable: true,
    editable: false
  },
  {
    field: 'parent_id',
    headerName: 'Parent Asset ID',
    width: 200,
    type: 'string',
    sortable: true,
    editable: true
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    type: 'string',
    sortable: true,
    editable: true
  },
  {
    field: 'customer_id',
    headerName: 'Customer ID',
    width: 100,
    type: 'string',
    sortable: true,
    editable: false
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    width: 300,
    editable: true,
  },
  {
    field: 'asset_type',
    headerName: 'Asset Type',
    sortable: true,
    width: 180,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(AssetType)
  },
  {
    field: 'base_resolution',
    headerName: 'Base Resolution',
    sortable: false,
    width: 160,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(timeResolutionToName),
  },
  {
    field: 'location',
    headerName: 'Location',
    sortable: false,
    width: 250,
    editable: true,
    type: 'string',
  },
  {
    field: 'meta',
    headerName: 'Meta',
    sortable: false,
    width: 250,
    editable: true,
    type: 'string',
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: true,
    width: 150,
    editable: false,
    type: 'dateTime'
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    sortable: true,
    width: 150,
    editable: false,
    type: 'dateTime'
  },
]


const CustomerAssetsTable = () => {
  const { customerId='' } = useParams<{customerId: string}>();

  const {
    data: customerData,
  } = useGetCustomerQuery(customerId)

  const selectedCustomer = customerData?.data;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading, isFetching, isError } = useListCustomerAssetsQuery(
    {
      page: page + 1,
      perPage: pageSize,
      customerId: customerId,
    },
    { skip: !selectedCustomer });

  const rowCount = data?.meta?.pagination?.total;

  const [createAssets, createAssetsState] = useCreateCustomerAssetsMutation();
  const [updateAssets, updateAssetsState] = useUpdateCustomerAssetsMutation();
  const [deleteAssets, deleteAssetsState] = useDeleteCustomerAssetsMutation();

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];
  const rowsParsed = rows.map((value: ICustomerAsset) => ({
    ...value,
    meta: JSON.stringify(value.meta),
    location: JSON.stringify(value.location)
  }));

  const onSave = (rowsToSave: ICustomerAsset[]) => {
    // Make sure that JSON fields are converted to valid JSON.
    let rowDidFail = false;
    const validatedRows: Partial<ICustomerAsset>[] = rowsToSave.map((value: ICustomerAsset) => {
      try {
        return {
          ...value,
          parent_id: value.parent_id && value.parent_id.length > 0 ? value.parent_id : undefined,
          meta: JSON.parse((value.meta as string).length === 0 ? '{}' : value.meta as string),
          location: JSON.parse((value.location as string).length === 0 ? '{}' : value.location as string),
        }
      } catch {
        rowDidFail = true;
      }
      return value;
    });

    if (rowDidFail) {
      alert('Couldn\'t parse one or more JSON properties. Did you enter valid JSON?');
      return;
    }
    // Check which rows are new (need to create) and which ones are existing (need to update).
    const existingIds = rows.map((value: ICustomerAsset) => value.id);
    // These rows should always have an 'id', but we do this to keep TS happy.
    const rowsToCreate = validatedRows.filter((value: Partial<ICustomerAsset>) => value.id && !existingIds.includes(value.id));
    const rowsToUpdate = validatedRows.filter((value: Partial<ICustomerAsset>) => value.id && existingIds.includes(value.id));
    createAssets(rowsToCreate);
    updateAssets(rowsToUpdate);
  }

  const upsertAssetsState = {
    isError: createAssetsState.isError || updateAssetsState.isError,
    isLoading: createAssetsState.isLoading || updateAssetsState.isLoading,
  }

  const now = new Date();

  return (
    <EditableTable<ICustomerAsset>
      editable={true}
      rows={rowsParsed}
      rowCount={rowCount}
      onPageSizeChange={setPageSize}
      onPageChange={setPage}
      columnSchema={ColumnSchema}
      pageSize={pageSize}
      showToolbar={false}
      saveCallback={onSave}
      saveState={upsertAssetsState}
      deleteCallback={deleteAssets}
      deleteState={deleteAssetsState}
      enableAddButton={true}
      emptyRowFactory={() => {
        const row = {
          id: 'new-' + now.valueOf().toString(),
          name: '',
          description: '',
          created_at: now.toISOString(),
          updated_at: now.toISOString(),
          customer_id: customerId,
          parent_id: '',
          asset_type: AssetType.METER,
          base_resolution: '15m',
          location: '{}',
          meta: '{}',
        };
        return row;
      }}
    />
  );
}


export default CustomerAssetsTable;
