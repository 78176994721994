import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCurrentEnvironment } from 'modules/app/selectors';
import { RootState } from 'modules/store';

import { getToken } from 'utils/auth';
import { Environment, IApiMetaResponse } from './types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AUTH_URL } from 'constants/resources';


export const bootToLogin = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'carbon-dashboard',
  });

  window.location.href = `${AUTH_URL}/?${params}`;
}

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {

  // Get the current environment from the store. This changes the baseUrl.
  // const environment = useSelector(getCurrentEnvironment) || Environment.Development;
  const environment = getCurrentEnvironment(api.getState() as RootState) || Environment.Localhost;

  const baseUrlForEnv = {
    [Environment.Development]: 'https://corporate-carbon.api.qa-singularity.energy/api/admin/',
    [Environment.Production]: 'https://corporate-carbon.api.singularity.energy/api/admin/',
    [Environment.Localhost]: 'http://lcl.qa-singularity.energy:5010/api/admin/',
  }[environment];

  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrlForEnv,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers
    },
    credentials: 'include',
  });
  let result = await baseQuery(args, api, extraOptions)
  const status = result.error && result.error.status
  if (status === 401 || status === 403) {
    // try to get a new token
    bootToLogin();
  }
  return result
}


export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'adminApi',

  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: dynamicBaseQuery,

  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['Users', 'Customers', 'CustomerInterventions', 'CustomerProperties', 'CustomerAssets', 'Connectors', 'Tables', 'CustomerAssetEvents', 'DataIssues', 'RegionEvents', 'WorkerJobs'],

  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({})
});


// A mutation that batches multiple API calls.
//
// The result will have type:
//    Success: { data: ReturnType[], error: undefined }
//    Failure: { data: undefined, error: FetchBaseQueryError }
export function buildBatchMutation<ReturnType, ArgType>(
  // Pass in the endpoint builder that's supplied by `endpoints`. Until type
  // inference is improved, make sure that the tags and API name below are up to date.
  build: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
                         "CustomerInterventions" | "Users" | "Customers" | "CustomerProperties" | "CustomerAssets" | "Connectors" | "Tables" | "CustomerAssetEvents" | 'DataIssues' | 'RegionEvents' | 'WorkerJobs',
                         "adminApi">,
  builderFunctions: {
    // Takes in `arg` for a single query and returns a `FetchArgs` object for that query.
    fetchArgsFn: (arg: ArgType) => FetchArgs,
    // Determines the tags that this mutation invalidates.
    // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
    invalidatesTagsFn: (result: ReturnType[] | undefined,
                        error: FetchBaseQueryError | undefined,
                        args: ArgType[]) => any[]
  }
) {
return build.mutation<ReturnType[], ArgType[]>({
  async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
    const promises = args.map(async (arg: ArgType) => {
      const result = await fetchWithBQ(builderFunctions.fetchArgsFn(arg)) as QueryReturnValue<ReturnType, FetchBaseQueryError, IApiMetaResponse>;
      if (result.data) {
        return { data: result.data as ReturnType };
      // In some cases (e.g a 204 response), there will be no returned data, but
      // the query still succeeded.
      } else if (result.meta?.response.ok) {
        return { data: 'ok' };
      } else {
        return { error: result.error as FetchBaseQueryError };
      }
    });
    const results = await Promise.all(promises);

    if (!results.every((r) => r.data)) {
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: 'One or more of the batched mutations failed.'
        }
      }
    }
    return {
      data: results.map(r => r.data as ReturnType),
    };
  },
  invalidatesTags: builderFunctions.invalidatesTagsFn
});
}
