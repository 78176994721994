import Container from '@mui/material/Container';

import CustomerTable from './CustomerTable';
import CreateCustomerForm from './CreateCustomerForm';
import CustomerDropdown from 'components/CustomerDropdown';


const CustomersPage = () => {
  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h1>Customer Management</h1>
      <CustomerDropdown />
      <CustomerTable/>
      <CreateCustomerForm/>
    </Container>
  )
}


export default CustomersPage;
