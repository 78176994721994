import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import { useGetTableInfoQuery, useListTablesQuery, useRunMigrationMutation } from 'api/database';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useState } from 'react';
import ConfirmDialog from 'components/ConfirmDialog';


const TableSizeSchema = [
  {
    field: 'table',
    headerName: 'Table Name',
    width: 300,
    type: 'string',
    sortable: true,
  },
  {
    field: 'size',
    headerName: 'Table Size',
    width: 300,
    type: 'number',
    sortable: true,
  },
];


const TableSchemaSchema = [
  {
    field: 'column',
    headerName: 'Column Name',
    width: 300,
    type: 'string',
    sortable: true,
  },
  {
    field: 'type',
    headerName: 'Column Type',
    width: 300,
    type: 'string',
    sortable: true,
  },
];


const SchemaTable = (props: { tableNames: string[] }) => {
  const [selectedTable, setSelectedTable] = useState('users');

  const { data, isLoading, isFetching, isError } = useGetTableInfoQuery(selectedTable);
  const rows = (data?.data?.columns && !isError && !isLoading && !isFetching) ? data.data.columns : [];
  const rowsWithId = rows.map(r => ({ ...r, id: r.column}));

  const dropdownOptions = props.tableNames.map(name => <MenuItem value={name} key={name}>{name}</MenuItem>);

  return (
    <>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="table-dropdown">Table</InputLabel>
      <Select
        labelId="table-dropdown"
        id="table-dropdown"
        value={selectedTable}
        label="Table"
        onChange={(event: SelectChangeEvent) => setSelectedTable(event.target.value) }
      >
        {dropdownOptions}
      </Select>
    </FormControl>
    <DataGrid
      loading={isLoading}
      editMode='cell'
      rows={rowsWithId}
      columns={TableSchemaSchema}
      // The column buffer determines how many invisible columns on either side
      // of the view are rendered. To avoid render hooks being called conditionally
      // (and throwing an exception), we need to render all columns all the time.
      columnBuffer={10}
      pageSize={10}
      rowsPerPageOptions={[5, 10, 20]}
      autoHeight={true}
      density={"compact"}
    />
    </>
  );
}


const DatabasePage = () => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { data, isLoading, isFetching, isError } = useListTablesQuery();
  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];
  const rowsWithId = rows.map(r => ({ ...r, id: r.table}));

  const [runMigration, runMigrationState] = useRunMigrationMutation();

  const migrateResponse = <>
      <p><strong>Response:</strong></p>
      <pre style={{overflow: 'auto'}}>{JSON.stringify(runMigrationState.data, undefined, 2)}</pre>
    </>;

  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h1>Database Admin</h1>
      <h2>Table Stats</h2>
      <DataGrid
        loading={isLoading}
        editMode='cell'
        rows={rowsWithId}
        columns={TableSizeSchema}
        // The column buffer determines how many invisible columns on either side
        // of the view are rendered. To avoid render hooks being called conditionally
        // (and throwing an exception), we need to render all columns all the time.
        columnBuffer={10}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight={true}
        density={"compact"}
      />
      <h2>Table Schema</h2>
      <SchemaTable tableNames={rows.map(r => r.table)}/>
      <h2>Database Migration</h2>
      <LoadingButton
        loading={runMigrationState.isLoading}
        color={runMigrationState.isError ? 'error' : 'primary'}
        startIcon={<ArrowForwardIcon/>}
        variant="outlined"
        size="small"
        onClick={() => setConfirmDialogOpen(true)}
      >
        Run SQL Migration
      </LoadingButton>
      <ConfirmDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        title={'Are you sure?'}
        content={'To run the migration, type in \'migrate\' below.'}
        requiredInput={'migrate'}
        onConfirm={() => runMigration()}
      />
      {
        runMigrationState.data ? migrateResponse : ''
      }
    </Container>
  )
}


export default DatabasePage;
