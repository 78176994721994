export interface IApiErrorResponse {
  data: {
    error_code: string,
    error_message: string
  }
}


export interface IApiMetaResponse {
  response: {
    ok: boolean
    status: number
    statusText: string
  }
}


export interface ICustomer {
  id: string
  parent_id: string | null
  name: string
  status: string
  created_at: string
  api_key_id: string | null
  api_key_value: string | null
  usage_plan_id: string | null
}


export enum CustomerPropertyTypes {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  JSON = 'JSON',
  BOOLEAN = 'BOOLEAN',
}


export enum CustomerPropertyNamespaces {
  SETTINGS = 'SETTINGS',
  GATES = 'GATES',
  PRIVATE = 'PRIVATE',
  NONE = 'NONE',
}


export interface ICustomerProperty {
  name: string
  namespace: CustomerPropertyNamespaces
  property_value?: string
  value: string | number | object | boolean
  type: CustomerPropertyTypes
}

export interface IRawCustomerProperty {
  property_name: string
  property_type: CustomerPropertyTypes
  property_namespace: CustomerPropertyNamespaces
  property_value: string | number | object | boolean
}

export enum UserRoles {
  UADMIN = 'uadmin',
  SADMIN = 'sadmin',
  ADMIN = 'admin',
  OWNER = 'owner',
  USER = 'user',
  BILLING = 'billing',
  CONTRIBUTOR = 'contributor',
  VISITOR = 'visitor'
}

export interface IUser {
  email: string,
  name: string,
  id: string,
  customer_id: string,
  role: UserRoles
}


export interface ICustomerIntervention {
  id: string
  customer_id: string
  start_date: string | null
  end_date: string | null
  intervention_type: InterventionType
  name: string
  description: string
  status: InterventionStatus
  params: {[param_id: string]: ICustomerInterventionParam}
}


export interface ICustomerInterventionParam {
  param_id: string
  name: string
  description: string
  value: number
  min_value: number
  max_value: number
  units: string
  user_can_modify: boolean
  params: any
}


// This should be copied from the Python API and kept up to date!
export enum InterventionType {
  ROOFTOP_SOLAR = 'rooftop_solar',
  GREEN_POWER_PURCHASE = 'green_power_purchase',
  GENERIC_EFFICIENCY_UPGRADE = 'generic_efficiency_upgrade',
  CARBON_CREDIT_PURCHASE = 'carbon_credit_purchase',
  ENERGY_STORAGE = 'energy_storage',
  XCEL_LIGHTING_UPGRADE_REBATE = 'xcel/lighting_upgrade_rebate'
}


export enum Environment {
  Development = 'development',
  Production = 'production',
  Localhost = 'localhost'
}


export enum InterventionStatus {
  SUGGESTED = 'suggested',
  PLANNED = 'planned',
  IMPLEMENTED = 'implemented'
}


export interface IPaginationMeta {
  first: number
  prev: number
  this: number
  next: number
  last: number
  total?: number
  total_items?: number
}


export enum AssetType {
  METER = 'electrical_meter',
  PV = 'solar_panel',
  BATTERY = 'battery',
}


export interface ICustomerAsset {
  id: string
  name: string
  description: string
  created_at: string
  updated_at: string
  customer_id: string
  parent_id: string
  asset_type: AssetType
  base_resolution: string
  location: object | string
  meta: object | string
}


export interface IConnector {
  id: string
  status: string
  created_at: string
  updated_at: string
  customer_id: string
  provider: string
  external_account_id: string
  params: object | string
  meta: object | string
}


export enum ConnectorStatuses {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',    // action that the user took
  SUSPENDED = 'SUSPENDED',  // action that the system or admin took
}


export enum TimeResolution {
  MINUTES_15 = '15m',
  HOUR = '1h',
  DAY = '1d',
  MONTH = '1M',
  YEAR = '1Y',
}


export interface IDataIssue {
  dedup_key: string
  event_type: string
  issue_type: string
  meta: {
    referenced_issue_scraped_at?: string
    validated_at?: string
  }
  next_retry: string
  recorded_at: string
  region: string
  source: string
  start_date: string
}


export interface IRegionEvent {
  dedup_key: string
  region: string
  event_type: string
  meta: Record<string, unknown>
  data: Record<string, unknown>
  start_date: string
}

export interface IWorkerJob {
  id: string
  name: string
  status: string
  data: Record<string, unknown>
  meta: Record<string, unknown>
  created_at: string
  updated_at: string
  root_job_id?: string | null
}