import { api } from './base'
import { ICustomer, ICustomerProperty, IPaginationMeta, IRawCustomerProperty } from './types'


interface IGetCustomersParams {
  page?: number
  perPage?: number
  customerId: string
  environment: string
}


export interface IGetCustomersResponse {
  data: ICustomer[]
  meta: {
    pagination: IPaginationMeta
  }
}


export interface ICreateCustomerResponse {
  data: ICustomer
}

interface IFetchCustomerResponse {
  data: ICustomer
}


export interface IGetCustomerPropertiesResponse {
  data: ICustomerProperty[]
}


export const customerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomer: build.query<IFetchCustomerResponse, string>({
      query: (custId) => `customers/${custId}`,
      providesTags: (res) => res ? [{type: 'Customers', id: res.data.id }] : [],
    }),

    getCustomers: build.query<IGetCustomersResponse, Partial<IGetCustomersParams>>({
      query: (params: Partial<IGetCustomersParams>) => {
        return {
          url: 'customers',
          method: 'GET',
          params: {
            page: params.page || 1,
            per_page: params.perPage || 20,
            customer_id: params.customerId
          }
        }
      },
      // TODO(milo): More fine-grained tagging here.
      providesTags: [{ type: 'Customers', id: 'LIST' }]
    }),
    createCustomer: build.mutation<ICreateCustomerResponse, Partial<ICustomer>>({
      query: (customer: Partial<ICustomer>) => {
        return {
          url: 'customers',
          method: 'POST',
          body: customer
        }
      },
      invalidatesTags: [{ type: 'Customers' }]
    }),
    getCustomerProperties: build.query<IGetCustomerPropertiesResponse, string>({
      query: (customerId: string) => {
        return {
          url: `customers/${customerId}/properties`,
          method: 'GET',
        }
      },
      providesTags: (result, _error, customerId) => {
        if (result) {
          return [{ type: 'CustomerProperties', id: customerId }];
        } else {
          return [];
        }
      }
    }),
    upsertCustomerProperty: build.mutation<ICustomerProperty, {property: IRawCustomerProperty, customerId: string}>({
      query: ({property, customerId}: {property: IRawCustomerProperty, customerId: string}) => {
        return {
          url: `customers/${customerId}/properties`,
          method: 'POST',
          body: property,
        }
      },
    }),
  })
});


export const {
  useLazyGetCustomerQuery,
  useGetCustomerQuery,
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useGetCustomerPropertiesQuery,
  useUpsertCustomerPropertyMutation,
} = customerApi;
