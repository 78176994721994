import { useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import EditableTable from 'components/EditableTable';
import { useGetCustomersQuery } from 'api/customers';
import { useAppDispatch } from 'modules/store';
import { setSelectedCustomer } from 'modules/customer/slice';
import { ICustomer } from 'api/types';
import { useChangeMyCustomerMutation } from 'api/users';
import { receiveUser } from 'modules/user/slice';
import { useNavigate } from 'react-router-dom';


const ColumnSchema: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    type: 'string'
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    width: 200,
    editable: true,
    type: 'string'
  },
  {
    field: 'parent_id',
    headerName: 'Parent ID',
    sortable: true,
    width: 100,
    editable: true,
    type: 'string'
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 100,
    editable: true,
    type: 'string'
  },
  {
    field: 'api_key_id',
    headerName: 'API Key ID',
    sortable: true,
    width: 150,
    type: 'string',
    editable: true // So that the user can click into this field.
  },
  {
    field: 'api_key_value',
    headerName: 'API Key Value',
    sortable: true,
    width: 150,
    type: 'string',
    editable: true // So that the user can click into this field.
  },
  {
    field: 'usage_plan_id',
    headerName: 'Usage Plan ID',
    sortable: true,
    width: 150,
    type: 'string'
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: true,
    width: 150,
    type: 'dateTime',
  },
];


const CustomerTable = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const selectCustomer = (rows: ICustomer[]) => {
    if (rows.length > 0) {
      dispatch(setSelectedCustomer({customer: rows[0]}));
      nav(`/customers/${rows[0].id}`)
    }
  };

  const { data, isLoading, isFetching, isError } = useGetCustomersQuery({
    // We use zero-indexed pages here, whereas the API uses 1-indexed.
    page: page + 1,
    perPage: pageSize,
  });

  const [changeCustomerReq, changeCustomerRes] = useChangeMyCustomerMutation();

  const onImpersonateCustomer = async (rows: ICustomer[]) => {
    if (rows.length > 0) {
      const customerId = rows[0].id;
      try {
        const res = await changeCustomerReq(customerId).unwrap();
        receiveUser({ user: res.data });
      } catch (e) {
        console.warn('Failed to change customer', e);
      }
    }
  };

  const rowCount = data?.meta?.pagination?.total_items;

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];

  return (
    <EditableTable

      customButtonText='Select'
      customCallback={selectCustomer}
      customState={{isError: false, isLoading: false}}

      secondaryCustomButtonText='Impersonate'
      secondaryCustomCallback={onImpersonateCustomer}
      secondaryCustomState={{isError: !!changeCustomerRes.error, isLoading: changeCustomerRes.isLoading}}

      loading={isLoading || isFetching}
      editable={true}
      rows={rows}
      columnSchema={ColumnSchema}
      pageSize={pageSize}
      showToolbar={true}
      rowCount={rowCount}
      onPageChange={(newPage: number) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newSize: number) => {
        setPageSize(newSize);
      }}
    />
  );
}


export default CustomerTable;
