import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser } from "api/types";

interface IUserSlice {
  currentUser: null | IUser,
};


const initialState: IUserSlice = {
  currentUser: null,
};


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUser: (state, action: PayloadAction<{user: IUser}>) => {
      state.currentUser = action.payload.user;
    },
  },
});


export default userSlice.reducer;
export const { receiveUser } = userSlice.actions;
