import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import {equals} from 'ramda';

import { useGetCustomerPropertiesQuery, useGetCustomerQuery, useUpsertCustomerPropertyMutation } from 'api/customers';
import { CustomerPropertyNamespaces, CustomerPropertyTypes, ICustomerProperty } from 'api/types';

import CustomerPagesConfig from './PagesConfig';
import { CustomerAttributes, DEFAULT_PAGES_CONFIG, IDashboardPagesConfig } from './constants';
import CustomerPropertiesConfig from './PropertiesConfig';
import './style.css';


const DashboardConfigPage = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const {
    data: customerData,
  } = useGetCustomerQuery(customerId);
  const selectedCustomer = customerData?.data;
  const [availablePages, setAvailablePages] = useState(DEFAULT_PAGES_CONFIG.available_pages);
  const [defaultRegion, setDefaultRegion] = useState('ISONE');
  const [utcOffsetMin, setUtcOffsetMin] = useState(-5 * 60);
  const [allowSave, setAllowSave] = useState(false);

  const { data, isLoading, isFetching } = useGetCustomerPropertiesQuery(customerId, { skip: !selectedCustomer });
  const [upsertProperty, upsertPropertyState] = useUpsertCustomerPropertyMutation();

  const saveProp = () => {
    upsertProperty({
      property: {
        property_name: CustomerAttributes.PAGES_CONFIG,
        property_namespace: CustomerPropertyNamespaces.PRIVATE,
        property_value: JSON.stringify({available_pages: availablePages}),
        property_type: CustomerPropertyTypes.JSON,
      },
      customerId,
    });
    upsertProperty({
      property: {
        property_name: CustomerAttributes.DEFAULT_REGION,
        property_namespace: CustomerPropertyNamespaces.SETTINGS,
        property_value: defaultRegion,
        property_type: CustomerPropertyTypes.STRING,
      },
      customerId,
    });
    upsertProperty({
      property: {
        property_name: CustomerAttributes.UTC_OFFSET_MINS,
        property_namespace: CustomerPropertyNamespaces.PRIVATE,
        property_value: utcOffsetMin,
        property_type: CustomerPropertyTypes.NUMBER,
      },
      customerId
    });
  }

  useEffect(() => {
    const pagesProp = data?.data.find(d => d.name === CustomerAttributes.PAGES_CONFIG);
    const defaultRegionProp = data?.data.find(d => d.name === CustomerAttributes.DEFAULT_REGION);
    const utcOffsetMinProp = data?.data.find(d => d.name === CustomerAttributes.UTC_OFFSET_MINS);

    if (pagesProp) {
      setAvailablePages((pagesProp.value as IDashboardPagesConfig).available_pages);
    }

    if (defaultRegionProp) {
      setDefaultRegion(defaultRegionProp.value as string);
    }

    if (utcOffsetMinProp) {
      setUtcOffsetMin(utcOffsetMinProp.value as number)
    }
  }, [data]);

  // allow saving if any of the props changed
  useEffect(() => {
    const pagesProp = data?.data.find(d => d.name === CustomerAttributes.PAGES_CONFIG);
    const defaultRegionProp = data?.data.find(d => d.name === CustomerAttributes.DEFAULT_REGION);
    const utcOffsetMinProp = data?.data.find(d => d.name === CustomerAttributes.UTC_OFFSET_MINS);
    const props = [pagesProp, defaultRegionProp, utcOffsetMinProp];

    if (props.some(p =>!p)) {
      setAllowSave(true);
    } else {
      // pairs of props of [savedValue, editedValue]
      // for comparison to see if any of them changed
      const propPairs = [
        [((pagesProp as ICustomerProperty).value  as IDashboardPagesConfig).available_pages, availablePages],
        [(defaultRegionProp as ICustomerProperty).value, defaultRegion],
        [(utcOffsetMinProp as ICustomerProperty).value, utcOffsetMin],
      ];
      setAllowSave(propPairs.some(([saved, edited]) => !equals(saved, edited)));
    }
  }, [data, availablePages, defaultRegion, utcOffsetMin]);

  const showLoading = isLoading || isFetching || !data;


  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <div>
        <div className="dashboard-config-header--info">
          <h2>Customer Dashboard Management</h2>
          <LoadingButton onClick={saveProp} variant='contained' disabled={!allowSave} loading={upsertPropertyState.isLoading}>Save</LoadingButton>
        </div>
      </div>
      {showLoading ? (
        <CircularProgress style={{height: 40, width: 40, margin: '40px'}} />
      ) : (
        <>
          <CustomerPagesConfig availablePages={availablePages} onChange={setAvailablePages} />
          <CustomerPropertiesConfig
            isoRegionCode={defaultRegion}
            setIsoRegionCode={setDefaultRegion}
            utcOffsetMin={utcOffsetMin}
            setUtcOffsetMin={setUtcOffsetMin}
          />
        </>
      )}
    </Container>
  )
}


export default DashboardConfigPage;
