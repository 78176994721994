import { api } from './base';
import { IDataIssue, IPaginationMeta, IRegionEvent } from './types';


interface IListDataIssuesResponse {
  data: IDataIssue[]
  meta: {
    pagination: IPaginationMeta
  }
}


interface IListDataIssuesParams {
  page: number
  perPage: number
  region: string
  event_type: string
  start_date: string
  end_date: string
  sort_by_date: 'start_date' | 'recorded_at'
}


interface IFetchRegionEventResponse {
  data: IRegionEvent
}


export const DataIssuesApi = api.injectEndpoints({
  endpoints: (build) => ({
    listDataIssues: build.query<IListDataIssuesResponse, Partial<IListDataIssuesParams>>({
      query: (params: Partial<IListDataIssuesParams>) => {
        const queryParams: any = {
          page: params.page,
          per_page: params.perPage,
        }
        if (params.region) {
          queryParams.region = params.region
        }
        if (params.end_date) {
          queryParams.end_date = params.end_date
        }
        if (params.event_type) {
          queryParams.event_type = params.event_type
        }
        if (params.start_date) {
          queryParams.start_date = params.start_date
        }
        if (params.sort_by_date) {
          queryParams.sort_by_date = params.sort_by_date
        }
        return {
          url: 'data_issues',
          method: 'GET',
          params: queryParams,
        }
      },

      providesTags: (result, error, arg) => {
        const tags = [{ type: 'DataIssues' as const, id: 'LIST'}];
        if (!result || !result.data) {
          return tags;
        }
        tags.push(...result.data.map((value: IDataIssue) => ({ type: 'DataIssues' as const, id: value.dedup_key})));
        return tags;
      }
    }),

    fetchRegionEventForIssue: build.query<IFetchRegionEventResponse, string>({
      query: (dedup_key: string) => {
        return {
          url: `data_issues/${dedup_key}/event`,
          method: 'GET',
        }
      },

      providesTags: (result, error, arg) => {
        if (!result || !result.data) {
          return [];
        }
        return [{type: 'RegionEvents', id: result.data.dedup_key}];
      }
    }),
  })
});


export const {
  useListDataIssuesQuery,
  useFetchRegionEventForIssueQuery,
} = DataIssuesApi;
