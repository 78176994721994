import { api, buildBatchMutation } from './base';
import { ICustomerAsset, IPaginationMeta } from './types';


interface IListCustomerAssetsResponse {
  data: ICustomerAsset[]
  meta: {
    pagination: IPaginationMeta
  }
}


interface IListCustomerAssetsParams {
  page: number
  perPage: number
  customerId: string
}


export const customerAssetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    listCustomerAssets: build.query<IListCustomerAssetsResponse, Partial<IListCustomerAssetsParams>>({
      query: (params: Partial<IListCustomerAssetsParams>) => {
        return {
          url: 'customer_assets',
          method: 'GET',
          params: {
            page: params.page,
            per_page: params.perPage,
            customer_id: params.customerId
          }
        }
      },
      providesTags: (result, error, arg) => {
        const tags = [{ type: 'CustomerAssets' as const, id: 'LIST'}];
        if (!result || !result.data) {
          return tags;
        }
        tags.push(...result.data.map((value: ICustomerAsset) => ({ type: 'CustomerAssets' as const, id: value.id })));
        return tags;
      }
    }),
    createCustomerAssets: buildBatchMutation<ICustomerAsset, Partial<ICustomerAsset>>(
      build,
      {
          fetchArgsFn: (body: Partial<ICustomerAsset>) => {
          return {
            url: `customer_assets`,
            method: 'POST',
            body: body
          };
        },
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'CustomerAssets', id: 'LIST' }] : [];
        }
      }
    ),
    updateCustomerAssets: buildBatchMutation<ICustomerAsset, Partial<ICustomerAsset>>(
      build,
      {
        fetchArgsFn: (body: Partial<ICustomerAsset>) => {
          return {
            url: `customer_assets/${body.id}`,
            method: 'PATCH',
            body: body
          };
        },
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'CustomerAssets', id: 'LIST' }] : [];
        }
      }
    ),
    deleteCustomerAssets: buildBatchMutation<void, string | number>(
      build,
      {
        fetchArgsFn: (assetId: string | number) => ({ url: `customer_assets/${assetId}`, method: 'DELETE' }),
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'CustomerAssets', id: 'LIST' }] : [];
        }
      }
    )
  })
});


export const {
  useListCustomerAssetsQuery,
  useCreateCustomerAssetsMutation,
  useUpdateCustomerAssetsMutation,
  useDeleteCustomerAssetsMutation
} = customerAssetsApi;
