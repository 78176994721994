import { Environment } from "api/types";

let ResourcesConstants;

if (process.env.REACT_APP_ENV === 'prod') {
  ResourcesConstants = {
    AUTH_URL: 'https://login.singularity.energy',
    API_URL: 'https://corporate-carbon.api.singularity.energy',
    APP_ENVIRONMENT: Environment.Production,
  };
} else if (process.env.REACT_APP_ENV === 'dev') {
  ResourcesConstants = {
    AUTH_URL: 'https://login.qa-singularity.energy',
    API_URL: 'https://corporate-carbon.api.qa-singularity.energy',
    APP_ENVIRONMENT: Environment.Development,
  };
} else {
  ResourcesConstants = {
    AUTH_URL: 'https://login.qa-singularity.energy',
    API_URL: process.env.REACT_APP_API_URL || 'http://lcl.qa-singularity.energy:5010',
    APP_ENVIRONMENT: Environment.Localhost,
  };
}

export const AUTH_URL = ResourcesConstants.AUTH_URL
export const DEBUG = process.env.REACT_APP_ENV !== 'prod';
export const LOCAL = DEBUG && process.env.REACT_APP_ENV !== 'dev';
export const API_URL = ResourcesConstants.API_URL;
export const APP_ENVIRONMENT = ResourcesConstants.APP_ENVIRONMENT;
