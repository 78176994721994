import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface IConfirmDialogProps {
  open: boolean
  title: string
  content: string
  requiredInput?: string
  onConfirm: () => void
  setOpen: (open: boolean) => void;
}


export default function ConfirmDialog(props: IConfirmDialogProps) {
  const [inputValue, setInputValue] = React.useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }

  // If no requiredInput is spcified, the user can confirm right away. Otherwise,
  // the text they've typed must match.
  const confirmEnabled = !props.requiredInput || (inputValue === props.requiredInput);

  const onClose = () => {
    setInputValue('');
    props.setOpen(false);
  }

  return (
    <div>
      <Dialog open={props.open} onClose={onClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
          {
            props.requiredInput ? 
              <TextField
                autoFocus
                value={inputValue}
                margin="dense"
                id="confirm-required-input"
                label={`Type in '${props.requiredInput}' to confirm`}
                type="text"
                fullWidth
                variant="standard"
                onChange={onChange}
              /> : ''
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              props.onConfirm();
              onClose();
            }}
            disabled={!confirmEnabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
