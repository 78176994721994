import Container from '@mui/material/Container';

import CustomerAssetsTable from './CustomerAssetsTable';


const CustomerAssetsPage = () => {
  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h2>Edit Customer Assets</h2>
      <CustomerAssetsTable/>
    </Container>
  )
}


export default CustomerAssetsPage;
