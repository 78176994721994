
import { useState } from 'react';

import { ConnectorStatuses, IConnector } from 'api/types';
import { useCreateConnectorsMutation, useDeleteConnectorsMutation, useListConnectorsQuery, useUpdateConnectorsMutation } from 'api/connectors';

import EditableTable from 'components/EditableTable';

import { useGetCustomerQuery } from 'api/customers';
import { useParams } from 'react-router-dom';


const ColumnSchema = [
  {
    field: 'id',
    headerName: 'Connector ID',
    width: 300,
    type: 'string',
    sortable: true,
    editable: false
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    type: 'singleSelect',
    sortable: true,
    editable: true,
    valueOptions: Object.values(ConnectorStatuses)
  },
  {
    field: 'customer_id',
    headerName: 'Customer ID',
    width: 150,
    type: 'string',
    sortable: true,
    editable: false
  },
  {
    field: 'provider',
    headerName: 'Provider',
    sortable: false,
    width: 200,
    editable: true,
    type: 'string',
  },
  {
    field: 'external_account_id',
    headerName: 'External Account ID',
    sortable: true,
    width: 200,
    editable: true,
    type: 'string',
  },
  {
    field: 'params',
    headerName: 'Params',
    sortable: false,
    width: 250,
    editable: true,
    type: 'string',
  },
  {
    field: 'meta',
    headerName: 'Meta',
    sortable: false,
    width: 250,
    editable: true,
    type: 'string',
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: true,
    width: 300,
    editable: false,
    type: 'dateTime'
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    sortable: true,
    width: 300,
    editable: false,
    type: 'dateTime'
  },
]


const ConnectorsTable = () => {
  const { customerId='' } = useParams<{customerId: string}>();

  const {
    data: customerData,
  } = useGetCustomerQuery(customerId)

  const selectedCustomer = customerData?.data;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading, isFetching, isError } = useListConnectorsQuery(
    {
      page: page + 1,
      perPage: pageSize,
      customerId: customerId,
    },
    { skip: !selectedCustomer });

  const rowCount = data?.meta?.pagination?.total;

  const [createConnectors, createConnectorsState] = useCreateConnectorsMutation();
  const [updateConnectors, updateConnectorsState] = useUpdateConnectorsMutation();
  const [deleteConnectors, deleteConnectorsState] = useDeleteConnectorsMutation();

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];
  const rowsParsed = rows.map((value: IConnector) => ({
    ...value,
    meta: JSON.stringify(value.meta),
    params: JSON.stringify(value.params)
  }));

  const onSave = (rowsToSave: IConnector[]) => {
    // Make sure that JSON fields are converted to valid JSON.
    let rowDidFail = false;
    const validatedRows: Partial<IConnector>[] = rowsToSave.map((value: IConnector) => {
      try {
        return {
          ...value,
          external_account_id: value.external_account_id && value.external_account_id.length > 0 ? value.external_account_id : undefined,
          provider: value.provider && value.provider.length > 0 ? value.provider : undefined,
          meta: JSON.parse((value.meta as string).length === 0 ? '{}' : value.meta as string),
          params: JSON.parse((value.params as string).length === 0 ? '{}' : value.params as string),
        }
      } catch {
        rowDidFail = true;
      }
      return value;
    });

    if (rowDidFail) {
      alert('Couldn\'t parse one or more JSON properties. Did you enter valid JSON?');
      return;
    }
    // Check which rows are new (need to create) and which ones are existing (need to update).
    const existingIds = rows.map((value: IConnector) => value.id);
    // These rows should always have an 'id', but we do this to keep TS happy.
    const rowsToCreate = validatedRows.filter((value: Partial<IConnector>) => value.id && !existingIds.includes(value.id));
    const rowsToUpdate = validatedRows.filter((value: Partial<IConnector>) => value.id && existingIds.includes(value.id));
    createConnectors(rowsToCreate);
    updateConnectors(rowsToUpdate);
  }

  const upsertConnectorsState = {
    isError: createConnectorsState.isError || updateConnectorsState.isError,
    isLoading: createConnectorsState.isLoading || updateConnectorsState.isLoading,
  }

  const now = new Date();

  return (
    <EditableTable<IConnector>
      editable={true}
      rows={rowsParsed}
      rowCount={rowCount}
      onPageSizeChange={setPageSize}
      onPageChange={setPage}
      columnSchema={ColumnSchema}
      pageSize={pageSize}
      showToolbar={false}
      saveCallback={onSave}
      saveState={upsertConnectorsState}
      deleteCallback={deleteConnectors}
      deleteState={deleteConnectorsState}
      enableAddButton={true}
      emptyRowFactory={() => {
        const row = {
          id: 'new-' + now.valueOf().toString(),
          status: ConnectorStatuses.ACTIVE,
          created_at: now.toISOString(),
          updated_at: now.toISOString(),
          customer_id: customerId,
          provider: '',
          external_account_id: '',
          meta: '{}',
          params: '{}'
        };
        return row;
      }}
    />
  );
}


export default ConnectorsTable;
