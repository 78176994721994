import { api } from './base';
import { IPaginationMeta, IWorkerJob } from './types';


interface IListWorkerJobsResponse {
  data: IWorkerJob[]
  meta: {
    pagination: IPaginationMeta
  }
}


interface IListWorkerJobsParams {
  page: number
  perPage: number
  status: string
}

interface IFetchWorkerJobResponse {
  data: IWorkerJob
}

const workerJobsList = { type: 'WorkerJobs' as const, id: 'LIST'};


export const WorkerJobsApi = api.injectEndpoints({
  endpoints: (build) => ({
    listWorkerJobs: build.query<IListWorkerJobsResponse, Partial<IListWorkerJobsParams>>({
      query: (params: Partial<IListWorkerJobsParams>) => {
        const qs = new URLSearchParams({
          page: params.page?.toString() || '1',
          per_page: params.perPage?.toString() || '20',
          status: params.status || 'in_progress',
        });
        return {
          url: `worker_jobs?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (result, error, arg) => {
        const tags = [workerJobsList];
        if (!result || !result.data) {
          return tags;
        }
        tags.push(...result.data.map((value) => ({ type: 'WorkerJobs' as const, id: value.id})));
        return tags;
      }
    }),

    fetchWorkerJob: build.query<IFetchWorkerJobResponse, string>({
      query: (id: string) => {
        return {
          url: `worker_jobs/${id}`,
          method: 'GET',
        }
      },

      providesTags: (result, error, arg) => {
        if (!result || !result.data) {
          return [];
        }
        return [{type: 'WorkerJobs', id: result.data.id}];
      }
    }),

    pauseWorkerJob: build.mutation<IFetchWorkerJobResponse, string>({
      query: (id: string) => {
        return {
          url: `worker_jobs/${id}/pause`,
          method: 'POST',
        };
      },

      invalidatesTags: res => res ? [workerJobsList, {type: 'WorkerJobs', id: res.data.id}] : [],
    }),

    restartWorkerJob: build.mutation<IFetchWorkerJobResponse, string>({
      query: (id: string) => {
        return {
          url: `worker_jobs/${id}/restart`,
          method: 'POST',
        };
      },

      invalidatesTags: res => res ? [workerJobsList, {type: 'WorkerJobs', id: res.data.id}] : [],
    }),

  })
});


export const {
  useListWorkerJobsQuery,
  useFetchWorkerJobQuery,
  usePauseWorkerJobMutation,
  useRestartWorkerJobMutation,
} = WorkerJobsApi;
