import { api, buildBatchMutation } from './base';
import { IConnector, IPaginationMeta } from './types';


interface IListConnectorsResponse {
  data: IConnector[]
  meta: {
    pagination: IPaginationMeta
  }
}


interface IListConnectorsParams {
  page: number
  perPage: number
  customerId: string
}


export const ConnectorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    listConnectors: build.query<IListConnectorsResponse, Partial<IListConnectorsParams>>({
      query: (params: Partial<IListConnectorsParams>) => {
        return {
          url: 'connectors',
          method: 'GET',
          params: {
            page: params.page,
            per_page: params.perPage,
            customer_id: params.customerId
          }
        }
      },
      providesTags: (result, error, arg) => {
        const tags = [{ type: 'Connectors' as const, id: 'LIST'}];
        if (!result || !result.data) {
          return tags;
        }
        tags.push(...result.data.map((value: IConnector) => ({ type: 'Connectors' as const, id: value.id })));
        return tags;
      }
    }),
    createConnectors: buildBatchMutation<IConnector, Partial<IConnector>>(
      build,
      {
          fetchArgsFn: (body: Partial<IConnector>) => {
          return {
            url: `connectors`,
            method: 'POST',
            body: body
          };
        },
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'Connectors', id: 'LIST' }] : [];
        }
      }
    ),
    updateConnectors: buildBatchMutation<IConnector, Partial<IConnector>>(
      build,
      {
        fetchArgsFn: (body: Partial<IConnector>) => {
          return {
            url: `connectors/${body.id}`,
            method: 'PATCH',
            body: body
          };
        },
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'Connectors', id: 'LIST' }] : [];
        }
      }
    ),
    deleteConnectors: buildBatchMutation<void, string | number>(
      build,
      {
        fetchArgsFn: (connectorId: string | number) => ({ url: `connectors/${connectorId}`, method: 'DELETE' }),
        invalidatesTagsFn: (result, _error, args) => {
          return result ? [{ type: 'Connectors', id: 'LIST' }] : [];
        }
      }
    )
  })
});


export const {
  useListConnectorsQuery,
  useCreateConnectorsMutation,
  useUpdateConnectorsMutation,
  useDeleteConnectorsMutation
} = ConnectorsApi;
