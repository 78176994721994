import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import { useGetCustomerQuery } from "api/customers";

import './style.css';
import { useChangeMyCustomerMutation } from "api/users";
import { LoadingButton } from "@mui/lab";
import { receiveUser } from "modules/user/slice";
import { useAppDispatch, useAppSelector } from "modules/store";
import { getCurrentUser } from "modules/user/selectors";


const CustomerPage = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [changeCustomerReq, changeCustomerRes] = useChangeMyCustomerMutation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getCurrentUser);

  const {
    data,
    isFetching,
    isLoading,
  } = useGetCustomerQuery(customerId);

  const customer = data?.data;

  const loading = !customer || isLoading || isFetching;

  if (loading) {
    return <CircularProgress />;
  }

  const onImpersonateCustomer = async () => {
    try {
      const res = await changeCustomerReq(customerId).unwrap();
      dispatch(receiveUser({ user: res.data }));
    } catch (e) {
      console.warn('Failed to change customer', e);
    }
  };

  return <div>
    <h2>Customer Info</h2>
    <div>
      <LoadingButton onClick={onImpersonateCustomer} loading={changeCustomerRes.isLoading} disabled={customerId === user?.customer_id}>Impersonate</LoadingButton>
    </div>
    <div className="customer-data--container">
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">Name</div>
        <div className="customer-data-info--value">{customer.name}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">Created</div>
        <div className="customer-data-info--value">{new Date(customer.created_at).toLocaleString('default', {month: 'short', year: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit'})}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">ID</div>
        <div className="customer-data-info--value">{customer.id}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">Parent ID</div>
        <div className="customer-data-info--value">{customer.parent_id}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">Status</div>
        <div className="customer-data-info--value">{customer.status}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">API Key ID</div>
        <div className="customer-data-info--value">{customer.api_key_id || '(None)'}</div>
      </div>
      <div className="customer-data-info--container">
        <div className="customer-data-info--label">API Plan ID</div>
        <div className="customer-data-info--value">{customer.usage_plan_id || '(None)'}</div>
      </div>
    </div>
  </div>
}

export default CustomerPage;