import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { validateName } from 'utils/validation';
import { ICustomer } from 'api/types';
import { useCreateCustomerMutation } from 'api/customers';


export interface ICreateCustomerFormState extends ICustomer {}


const CreateCustomerForm = () => {
  const [values, setValues] = React.useState<ICreateCustomerFormState>({
    name: '',
    id: '',
    parent_id: '',
    status: '',
    created_at: '',
    api_key_id: '',
    api_key_value: '',
    usage_plan_id: '',
  });

  const [createCustomer, createCustomerApiState] = useCreateCustomerMutation();

  const handleChange =
    (prop: keyof ICreateCustomerFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const validateAll = () => {
    return validateName(values.name);
  }

  const onSubmit = () => {
    const payload = structuredClone(values);

    // Convert empty strings to nulls.
    Object.entries(payload).forEach((value) => {
      if ((value[1] as string).length === 0) {
        payload[value[0]] = undefined;
      }
    });

    createCustomer(payload);

    setValues({
      name: '',
      id: '',
      parent_id: '',
      status: '',
      created_at: '',
      api_key_id: '',
      api_key_value: '',
      usage_plan_id: '',
    });
  }

  return (
    <Box
      component="form"
      sx={{ mt: 4 }}
      autoComplete="off"
    >
      <h2>
        Create new customer
      </h2>
      <p>The new customer will be assigned a random ID by the API.</p>
      <div style={{ display: "flex", flexDirection: "column", minWidth: '300px', maxWidth: '500px' }}>
        <TextField
          required
          id="new-customer--name"
          label="Name"
          placeholder="Enter a name"
          sx={{mt: 2}}
          value={values.name}
          onChange={handleChange('name')}
          error={!validateName(values.name)}
          helperText={validateName(values.name) ? '' : 'Please enter a name (at least 4 characters).'}
        />
        <TextField
          id="new-customer--parent"
          label="Parent ID"
          placeholder="Enter the parent customer ID (optional)"
          sx={{mt: 4}}
          value={values.parent_id}
          onChange={handleChange('parent_id')}
        />
        <TextField
          id="new-customer--status"
          label="Status"
          placeholder="Status of this customer"
          sx={{mt: 4}}
          value={values.status}
          onChange={handleChange('status')}
        />
        <TextField
          id="new-customer--api-key-id"
          label="API Key ID"
          sx={{mt: 4}}
          value={values.api_key_id}
          onChange={handleChange('api_key_id')}
        />
        <TextField
          id="new-customer--api-key-value"
          label="API Key Value"
          sx={{mt: 4}}
          value={values.api_key_value}
          onChange={handleChange('api_key_value')}
        />
        <TextField
          id="new-customer--usage-plan-id"
          label="Usage Plan ID"
          sx={{mt: 4}}
          value={values.usage_plan_id}
          onChange={handleChange('usage_plan_id')}
        />
        <LoadingButton variant={'outlined'}
          loading={createCustomerApiState.isLoading}
          color={createCustomerApiState.isError ? 'error' : 'primary'}
          startIcon={<SaveIcon />}
          disabled={!validateAll()}
          sx={{mt: 4, mb: 2}}
          onClick={onSubmit}
        >
          Create
        </LoadingButton>
        {
          createCustomerApiState.isError ? <Typography color='error.main'>{JSON.stringify(createCustomerApiState.error, undefined, 2) || ''}</Typography> : ''
        }
        {
          createCustomerApiState.isSuccess ? <Typography color='success.main'>Created new customer!</Typography> : ''
        }
      </div>
    </Box>
  );
}


export default CreateCustomerForm;
