import { api, buildBatchMutation } from './base'
import { IUser } from './types'


interface IGetUsersParams {
  page?: number
  perPage?: number
  customerId: string
  environment: string
}


export interface IGetUsersResponse {
  data: IUser[]
  meta: any
}

export interface IUpdateMyCustomerIdResponse {
  data: IUser
}


export interface ICreateUserResponse {
  status: string
}


export interface IDeleteUsersResponse {
  status: string
}


export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<IGetUsersResponse, Partial<IGetUsersParams>>({
      query: (params: Partial<IGetUsersParams>) => {
        return {
          url: 'users',
          method: 'GET',
          params: {
            page: params.page || 1,
            per_page: params.perPage || 20,
            customer_id: params.customerId
          }
        }
      },
      providesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    createUser: build.mutation<ICreateUserResponse, Partial<IUser>>({
      query: (user) => {
        return {
          url: 'users',
          method: 'POST',
          body: user
        }
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    deleteUsers: buildBatchMutation<void, string | number>(
      build,
      {
        fetchArgsFn: (userId: string | number) => ({ url: `users/${userId}`, method: 'DELETE' }),
        invalidatesTagsFn: (result, _error, args) => {

          if (result) {
            return [{ type: 'Users', id: 'LIST' }];
          } else {
            return [];
          }
        }
      }
    ),
    changeMyCustomer: build.mutation<IUpdateMyCustomerIdResponse, string>({
      query: (customerId) => {
        return {
          url: `users/me/change-customer/?customer_id=${customerId}`,
          method: 'POST',
        }
      },
    }),
  })
});


export const { useGetUsersQuery, useCreateUserMutation, useDeleteUsersMutation, useChangeMyCustomerMutation } = userApi;
