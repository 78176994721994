export enum Pages {
  EXECUTIVE_VIEW = 'EXECUTIVE_VIEW',
  REAL_TIME = 'REAL_TIME',
  HISTORICAL = 'HISTORICAL',
  PROJECTIONS = 'PROJECTIONS',
  SCENARIOS = 'SCENARIOS',
  CUSTOMERS = 'CUSTOMERS',
  SETTINGS = 'SETTINGS',
  ASSET_MGMT = 'ASSET_MGMT',
  REC_INVENTORY = 'REC_INVENTORY',
  REC_ALLOCATION = 'REC_ALLOCATION',
  REC_PROGRAMS = 'REC_PROGRAMS',
  REC_REPORTS = 'REC_REPORTS',
  SOLAR_BATTERY_INT = 'SOLAR_BATTERY_INT',
  SOLAR_BATTERY_INT_HISTORICAL = 'SOLAR_BATTERY_INT_HISTORICAL',
  PROGRAM_SUMMARY = 'PROGRAM_SUMMARY'
}

export interface IDashboardPagesConfig {
  available_pages: Array<Pages>,
}

export const CustomerAttributes = {
  DEMO: 'is_demo_customer',
  LIVE_DATA: 'has_live_data',
  UTC_OFFSET_MINS: 'physical_utc_offset_mins',
  PAGES_CONFIG: 'dashboard_config.pages',
  DEFAULT_REGION: 'default_iso_region',
}

export const DEFAULT_PAGES_CONFIG: IDashboardPagesConfig = {
  available_pages: [
    Pages.EXECUTIVE_VIEW,
    Pages.REAL_TIME,
    Pages.HISTORICAL,
    Pages.PROJECTIONS,
    Pages.SCENARIOS,
  ],
}

export const PagesSections = [
  {
    name: 'Legacy Demo Pages',
    description: 'Pages that were used for the EPRI 2022 project. ("Utility carbon dashboard")',
    pages: [
      {
        page: Pages.EXECUTIVE_VIEW,
        label: 'Legacy Executive View',
        description: "The legacy executive view powers the demo page showing a high level overview of a customer's goals and whether they are on track to achieve them."
      },
      {
        page: Pages.REAL_TIME,
        label: 'Legacy Real-time View',
        description: "The legacy real-time view powers the demo page showing an overview of the real time generation, consumption, and grid status for a customer."
      },
      {
        page: Pages.HISTORICAL,
        label: 'Legacy Historical View',
        description: "The legacy historical view shows a demo page with consumption and emissions data over a couple years' time."
      },
      {
        page: Pages.PROJECTIONS,
        label: 'Legacy Projections View',
        description: "The legacy projections view shows a long term look at the cost and carbon reduction (or increase) over time for a customer."
      },
      {
        page: Pages.SCENARIOS,
        label: 'Legacy Scenarios View',
        description: "The legacy scenarios view allows customers to play with various interventions in order to reduce energy cost and emissions."
      },
    ]
  },
  {
    name: 'Utility REC Demo Pages',
    description: 'Pages that were used for Duke, TVA, etc. demos to showcase internal generation tracking & allocation',
    pages: [
      {
        page: Pages.REC_INVENTORY,
        label: 'REC Inventory page',
        description: "TODO: description"
      },
      {
        page: Pages.REC_ALLOCATION,
        label: 'REC Allocation page',
        description: "TODO: description"
      },
      {
        page: Pages.REC_PROGRAMS,
        label: 'REC Programs page',
        description: "TODO: description"
      },
      {
        page: Pages.REC_REPORTS,
        label: 'REC Reports page',
        description: "TODO: description"
      },
    ]
  },
  {
    name: 'Customer Specific Pages',
    description: 'Pages to support active customer integrations like HBS',
    pages: [
      {
        page: Pages.SOLAR_BATTERY_INT,
        label: 'Real time dashboard for battery control system (HBS)',
        description: 'Real time overview of the HBS solar / battery integration and control system. This is planned to be shown publicly within HBS.'
      },
      {
        page: Pages.SOLAR_BATTERY_INT_HISTORICAL,
        label: 'Historical analysis of battery control system (HBS)',
        description: 'Historical data for the HBS solar / battery integration and control system.'
      },
    ]
  },
  {
    name: 'Standard dashboard pages',
    description: 'Pages that are used across multiple use cases and will be available to a wide range of customers',
    pages: [
      {
        page: Pages.ASSET_MGMT,
        label: 'Asset management view',
        description: "This view allows customers to view and configure their assets (e.g. solar panels, electrical meters, etc.)"
      },
      {
        page: Pages.CUSTOMERS,
        label: 'Customer Admin View',
        description: "The customers view allows our customers to configure logins and accounts for their customers."
      },
      {
        page: Pages.SETTINGS,
        label: 'Settings view',
        description: "The settings view allows users to set various preferences for their account."
      },
      {
        page: Pages.PROGRAM_SUMMARY,
        label: 'Program Summary',
        description: "The landing page for a utility's customer that is subscribed to a particular utility program."
      }
    ]
  },

];