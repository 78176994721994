import Container from '@mui/material/Container';

import UserTable from './UserTable';
import CreateUserForm from './CreateUserForm';


const UsersPage = () => {
  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h2>User Management</h2>
      <UserTable/>
      <CreateUserForm/>
    </Container>
  )
}


export default UsersPage;
