import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GridColDef, GridRowId } from '@mui/x-data-grid';

import { useDeleteUsersMutation, useGetUsersQuery } from 'api/users';
import ConfirmDialog from 'components/ConfirmDialog';
import EditableTable from 'components/EditableTable';
import { getCurrentEnvironment } from 'modules/app/selectors';


const ColumnSchema: GridColDef[] = [
  {
    field: 'id',
    headerName: 'User ID',
    width: 100
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    width: 200
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    width: 250
  },
  {
    field: 'role',
    headerName: 'Role',
    sortable: true,
    width: 150
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 100
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: true,
    width: 150
  },
];


const UserTable = () => {
  const { customerId='' } = useParams<{customerId: string}>();

  const environment = useSelector(getCurrentEnvironment);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { data, isLoading, isFetching, isError } = useGetUsersQuery({
    page: page + 1,
    perPage: pageSize,
    customerId,
    environment,
  });

  const rowCount = data?.meta?.pagination?.total_items;

  const [deleteUsers, deleteUsersApiState] = useDeleteUsersMutation();
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];

  return (
    <div style={{ width: '100%' }}>
      <ConfirmDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        title={'Are you sure?'}
        content={'To delete these user(s), type in \'delete\' below.'}
        requiredInput={'delete'}
        onConfirm={() => deleteUsers(selectedRowIds as string[])}
      />
      <EditableTable
        editable={false}
        rows={rows}
        pageSize={pageSize}
        columnSchema={ColumnSchema}
        showToolbar={false}
        loading={isLoading || isFetching}
        rowCount={rowCount}
        onPageChange={(newPage: number) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newSize: number) => {
          setPageSize(newSize);
        }}
        deleteCallback={(userIdsToDelete: GridRowId[]) => {
          setSelectedRowIds(userIdsToDelete);
          setConfirmDialogOpen(true);
        }}
        deleteState={deleteUsersApiState}
      />
    </div>
  );
}


export default UserTable;
