
import { ICustomerProperty, CustomerPropertyNamespaces, CustomerPropertyTypes, IRawCustomerProperty } from 'api/types';
import { useGetCustomerPropertiesQuery, useGetCustomerQuery, useUpsertCustomerPropertyMutation } from 'api/customers';

import EditableTable from 'components/EditableTable';

import { useState } from 'react';
import { useParams } from 'react-router-dom';


const ColumnSchema = [
  {
    field: 'name',
    headerName: 'Property Name',
    width: 180,
    type: 'string',
    editable: true
  },
  {
    field: 'namespace',
    headerName: 'Property Namespace',
    sortable: true,
    width: 180,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(CustomerPropertyNamespaces)
  },
  {
    field: 'type',
    headerName: 'Property Type',
    sortable: true,
    width: 180,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(CustomerPropertyTypes),
  },
  {
    field: 'value',
    headerName: 'Property Value',
    sortable: true,
    width: 480,
    editable: true,
    type: 'string'
  },
]


interface ICustomerPropertyWithId extends ICustomerProperty {
  id: string
}


const CustomerPropertiesTable = () => {
  const { customerId='' } = useParams<{customerId: string}>();

  const {
    data: customerData,
  } = useGetCustomerQuery(customerId)

  const selectedCustomer = customerData?.data;

  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading, isFetching, isError } = useGetCustomerPropertiesQuery(customerId, { skip: !selectedCustomer });

  const [upsertProperty, upsertPropertyState] = useUpsertCustomerPropertyMutation();

  const onSave = (rows: ICustomerPropertyWithId[]) => {
    rows.forEach(prop => {
      const rawProp: IRawCustomerProperty = {
        property_name: prop.name,
        property_type: prop.type,
        property_namespace: prop.namespace,
        property_value: prop.property_value || prop.value,
      }
      upsertProperty({property: rawProp, customerId});
    });
  }

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];

  // Add an ID to each row, since the table expects a unique ID.
  const rowsWithId: ICustomerPropertyWithId[] = rows.map((row: ICustomerProperty) => {
    let value: string | number | boolean | object = row.value;
    if (typeof row.value === "object") {
      value = JSON.stringify(row.value);
    }
    return { ...row, id: customerId + ':' + row.name, value };
  });

  return (
    <EditableTable<ICustomerPropertyWithId>
      editable={true}
      rows={rowsWithId}
      onPageSizeChange={setPageSize}
      columnSchema={ColumnSchema}
      pageSize={pageSize}
      showToolbar={false}
      saveCallback={onSave}
      saveState={upsertPropertyState}
      enableAddButton={true}
      emptyRowFactory={() => {
        const row = {
          // Use a temporary ID for this row. It isn't actually used in the
          // database so all that matters is that it's unique in the frontend.
          id: (customerId) + ':' + Date.now().toString(),
          customer_id: customerId,
          name: 'new_property',
          namespace: CustomerPropertyNamespaces.SETTINGS,
          value: '',
          type: CustomerPropertyTypes.STRING,
        };
        return row;
      }}
    />
  );
}


export default CustomerPropertiesTable;
