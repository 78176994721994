import { api } from './base'


export interface IListTablesResponse {
  data: { size: number, table: string }[]
}


export interface IGetTableInfoResponse {
  data: {
    columns: { column: string, type: string }[],
    indices: { definition: string, index: string }[]
  }
}


export interface IRunMigrationResponse {
  status: string
  stderr: string
  stdout: string
}


export const databaseApi = api.injectEndpoints({
  endpoints: (build) => ({
    listTables: build.query<IListTablesResponse, void>({
      query: () => {
        return {
          url: 'db/tables',
          method: 'GET',
        }
      },
      providesTags: [{ type: 'Tables', id: 'LIST' }]
    }),
    getTableInfo: build.query<IGetTableInfoResponse, string>({
      query: (tableName: string) => {
        return {
          url: `db/tables/${tableName}`,
          method: 'GET',
        }
      },
      providesTags: [{ type: 'Tables', id: 'DROPDOWN' }]
    }),
    runMigration: build.mutation<IRunMigrationResponse, void>({
      query: () => {
        return {
          url: `db/migrate`,
          method: 'POST',
        }
      },
      invalidatesTags: [{ type: 'Tables', id: 'LIST' }, { type: 'Tables', id: 'DROPDOWN' }]
    })
  })
});


export const {
  useListTablesQuery,
  useGetTableInfoQuery,
  useRunMigrationMutation
} = databaseApi;
