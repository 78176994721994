import React from 'react';

import { useSelector } from 'react-redux';

import { getCurrentUser } from 'modules/user/selectors';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { validateEmail, validateName, validatePassword } from 'utils/validation';
import { UserRoles } from 'api/types';
import { useCreateUserMutation } from 'api/users';
import { useParams } from 'react-router-dom';


export interface ICreateUserFormState {
  name: string
  email: string
  password: string
  showPassword: boolean
}


const CreateUserForm = () => {
  const currentUser = useSelector(getCurrentUser);
  const { customerId='' } = useParams<{customerId: string}>();

  const [createUser, createUserApiState] = useCreateUserMutation();

  const onCreateNewUser = (createUserData: ICreateUserFormState) => {
    // The `id` will be set by the API.
    const user = {
      customer_id: customerId,
      name: createUserData.name,
      email: createUserData.email,
      password: createUserData.password,
      role: UserRoles.USER,
    };
    createUser(user);

    setValues({
      name: '',
      email: '',
      password: '',
      showPassword: false
    });
  }


  const [values, setValues] = React.useState<ICreateUserFormState>({
    name: '',
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange =
    (prop: keyof ICreateUserFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validateAll = () => {
    return validateEmail(values.email) &&
           validatePassword(values.password) &&
           validateName(values.name);
  }

  return (
    <Box
      component="form"
      sx={{ mt: 4 }}
      autoComplete="off"
    >
      <h2>
        Create new user
      </h2>
      <p>User will be attached to customer <strong>{currentUser?.customer_id}</strong></p>
      <div style={{ display: "flex", flexDirection: "column", minWidth: '300px', maxWidth: '500px' }}>
        <TextField
          required
          id="new-user--name"
          label="Name"
          placeholder="Enter a name"
          sx={{mt: 2}}
          value={values.name}
          onChange={handleChange('name')}
          autoComplete={"name"}
          error={!validateName(values.name)}
          helperText={validateName(values.name) ? '' : 'Please enter a name (at least 4 characters).'}
        />
        <TextField
          required
          type="email"
          id="new-user--email"
          label="Email"
          placeholder="Enter an email address"
          sx={{mt: 4}}
          value={values.email}
          onChange={handleChange('email')}
          autoComplete={"email"}
          error={!validateEmail(values.email)}
          helperText={validateEmail(values.email) ? '' : 'Please use a valid email address.'}
        />
        <FormControl sx={{mt: 4}}>
          <InputLabel htmlFor="new-user--password">Password</InputLabel>
          <OutlinedInput
            required
            type={values.showPassword ? 'text' : 'password'}
            id="new-user--password"
            label="Password"
            placeholder="Enter a default passwiord"
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            autoComplete={"new-password"}
            error={!validatePassword(values.password)}
          />
        </FormControl>
        <LoadingButton variant={'outlined'}
          loading={createUserApiState.isLoading}
          color={createUserApiState.isError ? 'error' : 'primary'}
          startIcon={<SaveIcon />}
          disabled={!validateAll()}
          sx={{mt: 4, mb: 2}}
          onClick={() => onCreateNewUser(values)}
        >
          Create
        </LoadingButton>
        {
          createUserApiState.isError ? <Typography color='error.main'>Something went wrong. Is that email already in use?</Typography> : ''
        }
        {
          createUserApiState.isSuccess ? <Typography color='success.main'>Created new user!</Typography> : ''
        }
      </div>
    </Box>
  );
}


export default CreateUserForm;
