import Container from '@mui/material/Container';

import ConnectorsTable from './ConnectorsTable';


const ConnectorsPage = () => {
  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h2>Connectors</h2>
      <ConnectorsTable/>
    </Container>
  )
}


export default ConnectorsPage;
