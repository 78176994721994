export function validateName(text: string) {
  return text.length >= 4;
}


export function validateEmail(text: string) {
  return text.includes('.') &&
          text.includes('@') &&
          text.indexOf('@') > 0 &&                   // Stuff before the '@'
          text.indexOf('.') < (text.length - 1);     // Stuff after the '.'
}


export function validatePassword(text: string) {
  return text.length > 4;
}
