import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Box from '@mui/material/Box';

import { allRegions } from 'constants/app';

interface ITimezone {
  name: string;
  value: number;
}

let allUtcOffsetOptions: ITimezone[]
const getAllUtcOptions = () => {
  if (allUtcOffsetOptions) return allUtcOffsetOptions;

  allUtcOffsetOptions = [];
  for (let i = -12; i < 13; i++) {
    if (i === 0) {
      allUtcOffsetOptions.push({ name: 'GMT (0 min)', value: 0 });
    } else {
      const offsetMin = i * 60;
      allUtcOffsetOptions.push({
        name: `GMT${i >= 0 ? '+' : ''}${i} (${offsetMin} min)`,
        value: offsetMin
      });
    }
  }
  return allUtcOffsetOptions;
}

const CustomerPropertiesConfig = ({
  isoRegionCode,
  setIsoRegionCode,
  utcOffsetMin,
  setUtcOffsetMin
}: {
  isoRegionCode?: string,
  setIsoRegionCode: (code: string) => void,
  utcOffsetMin?: number,
  setUtcOffsetMin: (offset: number) => void,
}) => {
  return (
    <Box sx={{ mt: 4 }}>
      <h3>
        Dashboard Config
      </h3>
      <p>Determine how the customer can see and use the dashboard</p>
      <div className="dashboard-config-customer-properties--container">
        <FormControl variant='standard'>
          <InputLabel id="dashboard-props-default-region-label">Default ISO Region</InputLabel>
          <Select
            labelId="dashboard-props-default-region-label"
            value={isoRegionCode}
            label="Region"
            onChange={(e) => setIsoRegionCode(e.target.value)}
          >
            {allRegions.map((region) => (<MenuItem key={region.code} value={region.code} selected={region.code === isoRegionCode}>{region.name} ({region.code})</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl variant='standard'>
          <InputLabel id="dashboard-props-default-utc-offset-label">Default UTC Offset</InputLabel>
          <Select
            labelId="dashboard-props-default-utc-offset-label"
            value={utcOffsetMin}
            label="Region"
            onChange={(e) => setUtcOffsetMin(e.target.value as number)}
          >
            {getAllUtcOptions().map((option) => (
              <MenuItem key={option.value} value={option.value} selected={option.value === utcOffsetMin}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}


export default CustomerPropertiesConfig;
