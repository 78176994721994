import { ISearchRegionEventsResponse } from "api/region_events";

function makeXDateFormat(dateResolution: string) {
  return {
    '5m': '%a %b %e %I:%M %p (%a, %b %e)',
    '1h': '%a %b %e %I:%M %p (%a, %b %e)',
  }[dateResolution];
}

export const eventTypeToPath: Record<string, string> = {
  consumed_carbon_intensity: "consumed/carbon-intensity",
  consumed_fuel_mix: "consumed/fuel-mix",
  generated_carbon_intensity: "generated/carbon-intensity",
  generated_fuel_mix: "generated/fuel-mix",
  interchange: "interchange",
  marginal_carbon_intensity: "marginal/carbon-intensity",
  marginal_fuel_mix: "marginal/fuel-mix",
  "raw.generated_fuel_mix": "generated/fuel-mix",
  "raw.interchange": "interchange",
  "forecast.generated_carbon_intensity": "generated/carbon-intensity/forecast",
  "forecast.generated_fuel_mix": "generated/fuel-mix/forecast",
  "forecast.hourly_generated_carbon_intensity": "generated/carbon-intensity/forecast",
  "forecast.hourly_generated_fuel_mix": "generated/fuel-mix/forecast",
  "forecast.hourly_marginal_carbon_intensity": "marginal/carbon-intensity/forecast",
  "forecast.hourly_marginal_fuel_mix": "marginal/fuel-mix/forecast",
  "forecast.marginal_carbon_intensity": "marginal/carbon-intensity/forecast",
  "forecast.marginal_fuel_mix": "marginal/fuel-mix/forecast",
}

export const makeSeriesFromSearchData = (response: ISearchRegionEventsResponse, eventType: string) => {
  const isCi = eventType.includes('carbon_intensity');
  const isGfm = eventType.includes('generated_fuel_mix');
  const isCfm = eventType.includes('consumed_fuel_mix');
  // const isForecast = eventType.includes('forecast');
  // const isHourly = eventType.includes('hourly');
  if (isCi) {
    const data = [
      {
        data: response.data.map(({start_date, data}) => ([new Date(start_date).valueOf(), data.consumed_rate_lb_per_mwh || data.generated_rate_lb_per_mwh || data.marginal_rate_lb_per_mwh])),
        name: 'Carbon Intensity',
      }
    ];
    // TODO: fill nulls between data points
    return data;
  } else if (isGfm || isCfm) {
    const groupedByFuel: Record<string, number[][]> = {};
    response.data.forEach(datum => {
      const epoch = new Date(datum.start_date).valueOf();
      Object.entries(datum.data).forEach(([fuelNameMw, generation]) => {
        if (!fuelNameMw.endsWith('_mw')) {
          return
        }
        if (!groupedByFuel[fuelNameMw]) {
          groupedByFuel[fuelNameMw] = [];
        }
        groupedByFuel[fuelNameMw].push([epoch, generation]);
      })
    });

    const data = Object.entries(groupedByFuel).map(([fuelNameMw, data]) => ({
      data,
      name: fuelNameMw.replace('_mw', ''),
    }))
    // TODO: fill nulls between data points
    return data;
  }
}


export const makeChartOptions = ({
  dateResolution,
  animated=false,
  chartAxisLabelY1,
  chartData,
  chartType,
  title,
}: {animated: boolean, dateResolution?: string, chartAxisLabelY1: string, chartData: any, chartType: string, title?: string}) => {
  let xDateFormat: string | boolean | undefined = false;
  if (dateResolution) {
    xDateFormat = makeXDateFormat(dateResolution);
  }

  let chartOptions: Record<string, unknown> = {
    chart: {
      type: chartType,
      style: {
        fontFamily: "'Inter', 'Helvetica Neue', 'Segoe UI', 'Roboto', '-apple-system', 'sans-serif'",
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
      },
      animation: animated
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: new Date().getTimezoneOffset()
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        animation: animated
      },
      scatter: {
        marker: {
          enabled: true
        }
      },
      column: {
        stacking: 'normal',
      },
      area: {
        stacking: 'percent',
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '14px',
        },
        xDateFormat: xDateFormat
      },
    },
    yAxis: {
      title: {
        text: chartAxisLabelY1,
        useHTML: true,
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      min: 0,
    },
    title: {
      text: title || '',
    },
    tooltip: {
      valueDecimals: 1,
      shared: true,
      crosshairs: true,
      // useHTML: true
      xDateFormat,
    },
    legend: {
      enabled: true,
      symbolWidth: 40,
      itemStyle: {
        fontSize: '14px',
        lineHeight: '21px',
        opacity: 0.9,
        fontWeight: 'normal',
      },
      itemMarginTop: 8,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      description: 'This is a default chart description that will be replaced in future versions.',
      enabled: false
    },
    series: chartData,
  };

  return chartOptions;

}