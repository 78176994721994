import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Environment } from "api/types";
import * as Constants from "constants/resources";

interface IAppSlice {
  currentEnvironment: Environment
};


const initialState: IAppSlice = {
  currentEnvironment: Constants.APP_ENVIRONMENT || Environment.Localhost,
};


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    switchEnvironment: (state, action: PayloadAction<{environment: Environment}>) => {
      state.currentEnvironment = action.payload.environment;
    },
  },
});


export default appSlice.reducer;
export const { switchEnvironment } = appSlice.actions;
