import Container from '@mui/material/Container';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import { Select, MenuItem, CircularProgress, Drawer, InputLabel, FormControl } from '@mui/material';
import { prop, sortBy } from 'ramda';
import { useState } from 'react';

import { allRegions } from 'constants/app';
import { useFetchRegionEventForIssueQuery, useListDataIssuesQuery } from 'api/data_issues';
import './style.css';


const orderedRegions = sortBy(prop('code'), allRegions);

const TableSizeSchema = [
  {
    field: 'issue_type',
    headerName: 'Issue',
    type: 'string',
    width: 120,
    sortable: true,
  },
  {
    field: 'region',
    headerName: 'Region',
    type: 'string',
    sortable: true,
  },
  {
    field: 'recorded_at',
    headerName: 'Validated At',
    type: 'string',
    width: 240,
    sortable: true,
  },
  {
    field: 'start_date',
    headerName: 'Event Start Date',
    type: 'string',
    width: 240,
    sortable: true,
  },
];


const dateFormat: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};

const eventOptions = [
  {value: 'generated_fuel_mix', label: 'Gen fuel mix'},
  {value: 'forecast.hourly_generated_fuel_mix', label: 'Forecast'},
]

const DataIssuesPage = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [viewingDedupKey, setViewingDedupKey] = useState<string | null>(null);
  const [viewingIssue, setViewingIssue] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string | null>('');
  const [selectedEventType, setSelectedEventType] = useState<string | null>('');
  const [sortBy, setSortBy] = useState<'recorded_at' | 'start_date'>('recorded_at');

  const { data, isLoading, isFetching, isError } = useListDataIssuesQuery(
    {
      page: page + 1,
      perPage: pageSize,
      region: selectedRegion || undefined,
      sort_by_date: sortBy,
      event_type: selectedEventType || undefined,
    }
  );

  const {
    data: regionEvent,
    isLoading: regionEventLoading,
    isFetching: regionEventFetching,
    isError: regionEventError
  } = useFetchRegionEventForIssueQuery(viewingDedupKey as string, {skip: !viewingDedupKey});

  const rows = (data?.data && !isError && !isLoading && !isFetching) ? data.data : [];
  const rowsWithId = rows.map(r => ({ ...r, id: `${r.dedup_key}-${r.issue_type}`, recorded_at: new Date(r.recorded_at).toLocaleString('default', dateFormat), start_date: new Date(r.start_date).toLocaleString('default', dateFormat)}));

  const setViewingRow = (row: GridRowParams) => {
    setViewingIssue(row.id as string);
    setViewingDedupKey(row.row.dedup_key as string);
  }

  const clearDrawer = () => {
    setViewingDedupKey(null);
    setViewingIssue(null);
  }

  return (
    <Container sx={{m: 2, mt: 4, textAlign: 'left'}} maxWidth={false}>
      <h1>Data Issues</h1>
      <div>
        <FormControl size="small" sx={{mb: 2, minWidth: 160, maxWidth: 160}}>
          <InputLabel id="data-issues-region-label">Region</InputLabel>
          <Select
            labelId="data-issues-region-label"
            value={selectedRegion}
            label="Region"
            onChange={(e) => setSelectedRegion(e.target.value)}
          >
            {orderedRegions.map((region) => (<MenuItem key={region.code} value={region.code} selected={region.code === selectedRegion}>{region.code}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{mb: 2, minWidth: 160, maxWidth: 160, mr: 2, ml: 2}}>
          <InputLabel id="data-issues-event-label">Event type</InputLabel>
          <Select
            labelId="data-issues-event-label"
            value={selectedEventType}
            label="Event type"
            onChange={(e) => setSelectedEventType(e.target.value)}
          >
            {eventOptions.map((event) => (<MenuItem key={event.value} value={event.value} selected={event.value === selectedEventType}>{event.label}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{mb: 2, minWidth: 160, maxWidth: 160}}>
          <InputLabel id="data-issues-sort-label">Order by</InputLabel>
          <Select
            labelId="data-issues-sort-label"
            value={sortBy}
            label="Order by"
            onChange={(e) => setSortBy(e.target.value as 'start_date' | 'recorded_at')}
          >
            <MenuItem value={'start_date'} selected={'start_date' === sortBy}>Start date</MenuItem>
            <MenuItem value={'recorded_at'} selected={'recorded_at' === sortBy}>Validated date</MenuItem>
          </Select>
        </FormControl>
      </div>
      <DataGrid
        loading={isLoading}
        editMode='cell'
        rows={rowsWithId}
        columns={TableSizeSchema}
        // The column buffer determines how many invisible columns on either side
        // of the view are rendered. To avoid render hooks being called conditionally
        // (and throwing an exception), we need to render all columns all the time.
        columnBuffer={10}
        onRowClick={setViewingRow}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight={true}
        density={"compact"}
        rowCount={data ? (data.meta.pagination.last * pageSize) : pageSize}
      />
      <Drawer
        anchor="right"
        open={!!viewingDedupKey}
        onClose={clearDrawer}
      >
        {(regionEventLoading || regionEventFetching) && <CircularProgress />}
        {regionEventError && <p style={{padding: '24px'}}>Failed to load region event</p>}
        {!regionEventError && !(regionEventLoading || regionEventFetching) && regionEvent &&
          <div className="data-issues-drawer--content">
            <h4>Data issue:</h4>
            <div className="data-issues-drawer--data">
              <pre>{JSON.stringify(rowsWithId.find(d => d.id === viewingIssue), null, 2)}</pre>
            </div>
            <h4>Region event:</h4>
            <div className="data-issues-drawer--data">
              <pre>{JSON.stringify(regionEvent, null, 2)}</pre>
            </div>
          </div>
        }
      </Drawer>
    </Container>
  )
}


export default DataIssuesPage;
